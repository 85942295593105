import { useState } from "react";

export interface LoginValidationOption {
  passwordLength: number | null;
}

const defaultOptions: LoginValidationOption = {
  passwordLength: null,
};

export function useLoginValidation(
  options: LoginValidationOption = defaultOptions
) {
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  return {
    validateCredentials(username: string, password: string) {
      const emailRegex = new RegExp(
        /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i
      );

      if (!emailRegex.test(username)) {
        setEmailError("Email is not in a valid format.");
        return false;
      }
      setEmailError("");

      if (!password) {
        setPasswordError("Password is required.");
        return false;
      }

      if (options.passwordLength && password.length < options.passwordLength) {
        setPasswordError(
          `Password must be longer than ${options.passwordLength} characters`
        );
        return false;
      }

      setPasswordError("");
      return true;
    },
    emailError,
    passwordError,
  };
}
