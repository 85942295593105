import { createTheme } from "@mui/material/styles";

export const QuizMeDarkTheme = createTheme({
  palette: {
    mode: "dark",
    // primary: {
    //   main: '#2c80b1',
    //   light: '#66afe3',
    //   dark: '#005481'
    // },
    // secondary: {
    //   main: '#56447c',
    //   light: '#846fab',
    //   dark: '#2a1c4f',
    // }
    primary: {
      main: "#039BE5",
    },
    secondary: {
      main: "#7356c1",
    },
  },
});

export const QuizMeLightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#039BE5",
    },
    secondary: {
      main: "#7356c1",
    },
  },
});
