import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

export interface SubscsribePromptDialogProps {
  onSubscribeClicked: () => void;
  onClose: () => void;
  open: boolean;
  type: "decks" | "cards";
}

export const SubscsribePromptDialog: React.FC<SubscsribePromptDialogProps> = ({
  open,
  onClose,
  onSubscribeClicked,
  type,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Free tier exceeded"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You have reached the maximum amount of {type} allowed for the free
          tier.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Close</Button>
        <Button onClick={onSubscribeClicked} autoFocus>
          Subscribe
        </Button>
      </DialogActions>
    </Dialog>
  );
};
