import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useLoginValidation } from "../../hooks/useLoginValidation";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { useAuthentication } from "@liscioapps/quizme-client-library";
import { useHistory } from "react-router-dom";
import { Alert, Paper, Typography } from "@mui/material";
import logo from "../../assets/quizme-logo-2022.png";
import { Link as RouterLink } from "react-router-dom";
import AppHeaderBar from "../../components/AppBar";
import { setTheme } from "../../reducers/appSettingsReducer";
import { useKeyPress } from "../../hooks/useKeypress";

export default function SignUp() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  const [loading, setLoading] = useState(false);

  const userLoggedIn = useAppSelector((_) => _.auth.userLoggedIn);

  const dispatch = useAppDispatch();
  const { push } = useHistory();

  useEffect(() => {
    if (userLoggedIn) {
      push("/app");
    } else {
      // we only support the light theme on the sign up page
      dispatch(setTheme("light"));
    }
  }, [userLoggedIn]);

  const authentication = useAuthentication(dispatch);
  const { emailError, passwordError, validateCredentials } = useLoginValidation(
    {
      passwordLength: 10,
    }
  );

  const submitSignup = async () => {
    if (!validateCredentials(username, password)) {
      return;
    }

    try {
      setLoading(true);

      const response = await authentication.signup(
        username.toLocaleLowerCase(),
        password
      );

      push("/app");
    } catch (error) {
      setLoading(false);
      if (error.data && error.data.errorMessage) {
        const errorMessageKey = error.data.errorMessage;
        // TODO: handle localizatio and error keys
        setLoginError("Error creating account.");
        return;
      }

      console.error(error);
    }
  };

  useKeyPress(["Enter", "NumpadEnter"], submitSignup);

  return (
    <div className="welcome-area">
      <React.Fragment>
        <AppHeaderBar />
        <Container component="main" maxWidth="xs" className="login-area">
          <CssBaseline />
          <Paper
            elevation={4}
            sx={{
              padding: 2,
              marginTop: 8,
            }}
          >
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={logo} alt="Logo" width={150} />
              <Typography variant="h4">Sign up</Typography>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      error={!!emailError}
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      helperText={emailError}
                      onChange={(_) => setUsername(_.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={!!passwordError}
                      helperText={passwordError}
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      onChange={(_) => setPassword(_.target.value)}
                    />
                  </Grid>
                  {loginError && (
                    <Grid item xs={12}>
                      <Alert severity="error">{loginError}</Alert>
                    </Grid>
                  )}
                </Grid>
                <Button
                  onClick={submitSignup}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign Up
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link component={RouterLink} to="/login" variant="body2">
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Container>
      </React.Fragment>
    </div>
  );
}
