import { Dialog, DialogContent, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { welcomeScreenConfig, WelcomeScreenProps } from "./WelcomeScreenConfig";

const AppIntro: React.FC<{ open: boolean; handleClose: () => void }> = ({
  open,
  handleClose,
}) => {
  const [slide, setSlide] = useState<WelcomeScreenProps>(
    welcomeScreenConfig[0]
  );
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    if (open) {
      setSlideIndex(0);
    }
  }, [open]);

  useEffect(() => {
    setSlide(welcomeScreenConfig[slideIndex]);
  }, [slideIndex]);

  const goToNextSlide = () => {
    if (slideIndex === welcomeScreenConfig.length - 1) {
      return;
    }
    setSlideIndex((current) => current + 1);
  };

  return (
    <Dialog maxWidth={"md"} fullWidth open={open} onClose={handleClose}>
      <DialogContent>
        <div
          style={{
            height: 400,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "70%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "100%" }}>
              <slide.icon />
            </div>
            <Typography
              style={{ marginTop: 10 }}
              align={"center"}
              variant={"h4"}
            >
              {slide?.title}
            </Typography>
            <Typography align={"center"} style={{ marginTop: 15 }}>
              {slide?.description}
            </Typography>
            <Typography align={"center"} style={{ marginTop: 15 }}>
              {slide?.description2}
            </Typography>
            {slide.footer && <slide.footer />}
            <div style={{ flexGrow: 2 }}></div>
            {slideIndex < welcomeScreenConfig.length - 1 ? (
              <Button variant={"contained"} onClick={goToNextSlide}>
                Next
              </Button>
            ) : (
              <Button variant={"contained"} onClick={handleClose}>
                Done
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AppIntro;
