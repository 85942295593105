import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../hooks/useAuth";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AuthenticationResultModel } from "@liscioapps/quizme-client-library";
import { Box } from "@mui/material";
import logo from "../../assets/quizme-logo-2022.png";

export default function ResetPassword(props: any) {
  const dispatch = useDispatch();
  let { passwordResetToken } = useParams<{ passwordResetToken: string }>();
  const { push } = useHistory();

  const { resetPassword } = useAuth();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    document.location.href = `quizme://resetPassword/${passwordResetToken}`;

    return () => {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    };
  }, []);

  const submitResetPassword = async () => {
    if (password !== confirmPassword) {
      setLoginError("Passwords must match");
      return;
    }

    try {
      // TODO: use for both reset password and change password
      const response: AuthenticationResultModel = await resetPassword(
        password,
        passwordResetToken
      );
      alert("Your password has been reset successfully");
      push("/app");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="Logo" />
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <Typography variant="h4">Reset Password</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete=""
            value={password}
            onChange={(_) => setPassword(_.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete=""
            value={confirmPassword}
            onChange={(_) => setConfirmPassword(_.target.value)}
          />
          <Button
            color="primary"
            onClick={submitResetPassword}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset My Password
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
