import { Alert, AlertProps, Snackbar, SnackbarProps } from "@mui/material";

export type QuizSnackbarState = {
  severity: AlertProps["severity"];
  show: boolean;
  message: string;
  autohideDuration?: number;
};

export interface QuizSnackbarProps {
  state: QuizSnackbarState;
  setState: React.Dispatch<React.SetStateAction<QuizSnackbarState>>;
  onClose?: () => void;
}

export const QuizSnackbar: React.FC<QuizSnackbarProps> = (props) => {
  const { state, setState } = props;
  const closeSnackbar = () => {
    setState((s) => ({ ...s, show: false }));
    if (props.onClose) {
      props.onClose();
    }
  };
  const handleSnackbarClose: SnackbarProps["onClose"] = (event, reason) => {
    if (reason === "timeout") {
      closeSnackbar();
    }
  };

  return (
    <Snackbar
      open={state.show}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={state.autohideDuration || 6000}
      onClose={handleSnackbarClose}
    >
      <Alert
        onClose={closeSnackbar}
        severity={state.severity}
        sx={{ width: "100%" }}
      >
        {state.message}
      </Alert>
    </Snackbar>
  );
};
