import {
  selectedDeckReducer,
  createMiddleWare,
  decksMiddleware,
  authReducer,
  deckReducer,
  reviewReducer,
  publicDecksMiddleware,
  selectedPublicDeckMiddleware,
  publicDeckReducer,
  selectedPublicDeckReducer,
} from "@liscioapps/quizme-client-library";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { appSettingsReducer } from "../reducers/appSettingsReducer";
import { appTitlesReducer } from "../reducers/appTitlesReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  decks: deckReducer,
  publicDecks: publicDeckReducer,
  selectedPublicDeck: selectedPublicDeckReducer,
  selectedDeck: selectedDeckReducer,
  settings: appSettingsReducer,
  titles: appTitlesReducer,
  review: reviewReducer,
});
export type RootState = ReturnType<typeof rootReducer>;

const middlewares = [
  createMiddleWare(decksMiddleware, (state: RootState) => state.decks),
  createMiddleWare(publicDecksMiddleware, (state: RootState) => state.publicDecks),
  createMiddleWare(selectedPublicDeckMiddleware, (state: RootState) => state.selectedPublicDeck),
];

export const appStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
});

export type AppDispatch = typeof appStore.dispatch;
