import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/store/store";

export const canAddDecksSelector = createSelector(
  [
    (s: RootState) => {
      return (
        s.auth.subscriptionSettings.numberOfDecksAllowed === null ||
        s.auth.subscriptionSettings.numberOfDecksAllowed >
          s.decks.decksList.length
      );
    },
  ],
  (subscriptionAllows) => subscriptionAllows
);

export const canAddCardsSelector = createSelector(
  [
    (s: RootState) => {
      if (s.auth.subscriptionSettings.numberOfCardsAllowed === null) {
        return true;
      }
      const count = s.selectedDeck.currentDeck.cards?.length ?? 0;
      return s.auth.subscriptionSettings.numberOfCardsAllowed > count;
    },
  ],
  (subscriptionAllows) => subscriptionAllows
);
