import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface PlanCardProps {
  title: string;
  price: string;
  features: string[];
  isSubscribed?: boolean;
  onClickBuy?: () => void;
}

export const PlanCard: React.FC<PlanCardProps> = ({
  title,
  price,
  features,
  onClickBuy,
  isSubscribed = false,
}) => {
  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        textAlign: "center",
      }}
    >
      <CardHeader title={title} sx={{ textAlign: "center", spacing: 10 }} />
      <Divider variant="middle" />
      <CardContent>
        <Typography variant="h4" align="center">
          {price}
        </Typography>
        <div style={{ paddingTop: 20 }}>
          {features.map((feature) => (
            <Typography align="center" key={feature}>
              {feature}
            </Typography>
          ))}
        </div>
      </CardContent>
      {isSubscribed ? (
        // <Avatar sx={{ bgcolor: green[500] }}>
        <CheckCircleIcon fontSize="large" color="success" />
      ) : (
        <>
          {/* <Divider variant="middle" /> */}
          <CardActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              sx={{ marginBottom: 2 }}
              variant="contained"
              color="primary"
              onClick={onClickBuy}
            >
              Buy
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};
