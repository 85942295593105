import {
  setUserHasOnboarded,
  useDateTimeFormatter,
  useStatsService,
  useUserEventsService,
} from "@liscioapps/quizme-client-library";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { purple } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { HomeDashboardStats } from "@liscioapps/quizme-client-library";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { useTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import AppIntro from "../AppIntro/Index";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";

const DueCardsComponent: React.FC<{
  dueCardsCount?: number;
  dueDecksCount?: number;
  nextDue?: string | null;
}> = ({ dueCardsCount, dueDecksCount, nextDue }) => {
  const history = useHistory();
  const { getTimeBetween } = useDateTimeFormatter();
  const handleReviewAll = () => {
    history.push("/app/review");
  };
  return dueCardsCount ? (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: purple[500] }}>
            <AccessTimeIcon />
          </Avatar>
        }
        title={<Typography variant="h5">{dueCardsCount} Due cards </Typography>}
        subheader={`From ${dueDecksCount} decks`}
      />
      <CardActions>
        <Button size="large" onClick={handleReviewAll}>
          Review
        </Button>
      </CardActions>
    </Card>
  ) : (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: purple[500] }}>
            <AccessTimeIcon />
          </Avatar>
        }
        title={<Typography variant="h5">No Cards due Today!</Typography>}
        subheader={
          nextDue &&
          `Next Due: ${getTimeBetween(new Date(), new Date(nextDue))}`
        }
      />
      <CardActions></CardActions>
    </Card>
  );
};

const StreakComponent = (props: { streak?: number }) => (
  <Card sx={{ mt: 2 }}>
    <CardHeader
      avatar={
        <Avatar sx={{ bgcolor: purple[500] }}>
          <TrendingUpIcon />
        </Avatar>
      }
      title={
        <Typography variant="h5">
          {props.streak ? `${props.streak} day streak` : `No streak yet`}
        </Typography>
      }
    />
    <CardContent>
      <Typography variant="body2">
        {props.streak
          ? `You have a great streak going. Keep it up!`
          : `Review cards to start a new streak!`}
      </Typography>
    </CardContent>
  </Card>
);

const EmptyDecksComponent = () => {
  const history = useHistory();
  const handleCreateDeck = () => {
    history.push("/app/decks?showCreate=true");
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        title={<Typography variant="h5">Add your first deck</Typography>}
      />
      <CardContent>
        <Typography variant="body2">What do you want to learn?</Typography>
      </CardContent>
      <CardActions>
        <Button
          size="large"
          fullWidth
          variant="contained"
          onClick={handleCreateDeck}
        >
          Add Deck
        </Button>
      </CardActions>
    </Card>
  );
};

function DashboardScreen() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { getHomepageDashboard } = useStatsService();
  const [dashboard, setDashboard] = useState<HomeDashboardStats>();
  const history = useHistory();
  const { getOnboardCompleteStatus, setOnboardedComplete } =
    useUserEventsService();
  const [showAppIntro, setShowAppIntro] = useState(false);
  const userHasOnboarded = useAppSelector((s) => s.auth.userHasOnboarded);

  const loadOnboardedStatus = async () => {
    const onboarded = await getOnboardCompleteStatus();
    dispatch(setUserHasOnboarded(onboarded));
  };

  const showDemoDeck = async () => {
    await setOnboardedComplete("web");
    dispatch(setUserHasOnboarded(true));
    history.push(`/app/review?demo=true`);
  };

  const getStats = async () => {
    const stats = await getHomepageDashboard();
    setDashboard(stats);
  };

  useEffect(() => {
    loadOnboardedStatus();
    getStats();
  }, []);

  useEffect(() => {
    if (!userHasOnboarded) {
      showDemoDeck();
    }
  }, [userHasOnboarded]);

  const navigateToDeckDetails = (id: number) => {
    history.push(`/app/decks/${id}`);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4">Welcome back!</Typography>
      <DueCardsComponent
        nextDue={dashboard?.nextDue}
        dueCardsCount={dashboard?.dueCardsCount}
        dueDecksCount={dashboard?.dueDecksCount}
      />
      <StreakComponent streak={dashboard?.streak} />

      {dashboard?.recentDecks.length ? (
        <Paper sx={{ mt: 2 }}>
          <List
            sx={{ width: "100%", maxWidth: 360 }}
            subheader={
              <ListSubheader sx={{ bgcolor: "inherit" }}>
                Recent Decks
              </ListSubheader>
            }
          >
            {dashboard?.recentDecks.map((deck) => (
              <ListItem
                alignItems="flex-start"
                key={deck.id}
                onClick={() => navigateToDeckDetails(deck.id!)}
                sx={{ cursor: "pointer" }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <BookmarkBorderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={deck.title}
                  secondary={deck.description}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      ) : (
        <EmptyDecksComponent />
      )}

      <Card sx={{ mt: 2 }}>
        <CardHeader
          title={<Typography variant="h5">How does it work?</Typography>}
          subheader={
            <Typography variant={"body1"}>
              Review how QuizMe helps you learn more efficiently
            </Typography>
          }
        />
        <CardActions>
          <Button size="large" onClick={() => setShowAppIntro(true)}>
            Show me
          </Button>
        </CardActions>
      </Card>

      <AppIntro
        open={showAppIntro}
        handleClose={() => setShowAppIntro(false)}
      />
    </Container>
  );
}

export default DashboardScreen;
