import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { initDatadog } from "./Datadog";
import { appStore } from "./store/store";
import { Provider, useStore } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import {
  quizMeConfig,
  LocalforageStorageService,
} from "@liscioapps/quizme-client-library";
import CustomThemeProvider from "./providers/CustomThemeProvider";

quizMeConfig.apiEndpointUrl =
  (process.env.REACT_APP_API_ENDPOINT_URL as string) || "http://localhost:8080";
quizMeConfig.storageService = LocalforageStorageService.Instance;

initDatadog();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={appStore}>
      <CustomThemeProvider>
        <App />
      </CustomThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
