import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppTitlesState {
  appbarTitle: string;
  tabTitle: string;
}

const initialState: AppTitlesState = {
  appbarTitle: "QuizMe",
  tabTitle: "QuizMe",
};

export const appTitlesSlice = createSlice({
  name: "AppTitles",
  initialState,
  reducers: {
    setAppbarTitle: (state: AppTitlesState, action: PayloadAction<string>) => {
      state.appbarTitle = action.payload;
    },
    setTabTitle: (state: AppTitlesState, action: PayloadAction<string>) => {
      state.tabTitle = action.payload;
    },
  },
});

export const { setAppbarTitle, setTabTitle } = appTitlesSlice.actions;
export const appTitlesReducer = appTitlesSlice.reducer;
