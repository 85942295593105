import { quizMeConfig } from "@liscioapps/quizme-client-library";
import React, { useEffect } from "react";
import { Theme, ThemeProvider } from "@mui/material/styles";
import { QuizMeDarkTheme, QuizMeLightTheme } from "../configs/themeConfig";
import { useAppDispatch, useAppSelector } from "../hooks/useStore";
import { setTheme } from "../reducers/appSettingsReducer";

const CustomThemeProvider = (props: any) => {
  const [muiTheme, setMuiTheme] = React.useState<Theme>(QuizMeLightTheme);
  const themeType = useAppSelector((s) => s.settings.theme);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadTheme = async () => {
      let appThemeSetting = await quizMeConfig.storageService?.retrieve(
        "appTheme"
      );

      // if the user has selected a theme, we override the device theme
      dispatch(setTheme(appThemeSetting || "light"));
    };
    loadTheme();
  }, []);

  useEffect(() => {
    themeType === "dark"
      ? setMuiTheme(QuizMeDarkTheme)
      : setMuiTheme(QuizMeLightTheme);
  }, [themeType]);

  return <ThemeProvider theme={muiTheme}>{props.children}</ThemeProvider>;
};

export default CustomThemeProvider;
