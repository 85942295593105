import { useEffect } from "react";

export function useKeyPress(
  keyCodes: string[],
  action: () => void,
  enabled: boolean = true
): void {
  const handler = (e: KeyboardEvent) => {
    if (keyCodes.includes(e.code) && enabled) {
      e.preventDefault();
      action();
    }
  };

  /* 
    having useEffect run every time the handler changes prevents stale closures in passed in functions 
    that rely on state, or other captures values
  */

  useEffect(() => {
    console.log("adding event listener!!!!!");
    window.addEventListener("keydown", handler);
    return () => {
      console.log("removing event listener!!!!!");
      window.removeEventListener("keydown", handler);
    };
  }, [handler]);
}
