import { useAuthentication } from "@liscioapps/quizme-client-library";
import { useDispatch } from "react-redux";

export function useAuth() {
  const dispatch = useDispatch();
  const authenticationHook = useAuthentication(dispatch);

  return {
    ...authenticationHook, // extends the shared authenticationHook
  };
}
