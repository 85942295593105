import React from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { useHistory } from "react-router-dom";
import AppLogo from "../assets/quizme-app.png";

export default function AppHeaderBar() {
  const history = useHistory();

  return (
    <AppBar position="relative" color="secondary">
      <Toolbar>
        <img
          src={AppLogo}
          height="40"
          alt="QuizMe App logo showing two stacked cards"
          className="applogo"
        />
        <Link
          variant="h6"
          underline="hover"
          color="#fff"
          href="/"
          sx={{ flexGrow: 1 }}
        >
          Quiz Me
        </Link>
        {/* <Link component={RouterLink} to="/login" variant="body2">
                    <Button>
                        Login
                    </Button>

                </Link> */}
        <Button
          color="primary"
          variant="contained"
          sx={{ mx: 2 }}
          onClick={() => {
            history.push("/login");
          }}
        >
          Login
        </Button>
        <Box display={{ xs: "none", md: "block" }}>
          <Button
            color="primary"
            variant="contained"
            sx={{ mx: 2 }}
            onClick={() => {
              history.push("/signup");
            }}
          >
            Sign Up
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
