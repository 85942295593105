import { Redirect } from "react-router-dom";
import { useAppSelector } from "./hooks/useStore";

export const RequireAuth = ({ children }: any) => {
  const isAuthenticated = useAppSelector((state) => state.auth.userLoggedIn);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return children;
};
