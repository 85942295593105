import React from "react";
import { Link } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import AppHeaderBar from "../../components/AppBar";
import Footer from "../../components/Footer";

export default function Privacy() {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <AppHeaderBar />
        <Container
          component="main"
          sx={{
            flexGrow: 2,
            marginY: 4,
          }}
        >
          <Typography variant="h3" component="h1">
            Privacy Policy
          </Typography>
          <Container sx={{ marginY: 3 }}>
            <Typography variant="subtitle2">
              Liscio Apps, LLC <br />
              Privacy Policy <br />
              Effective as of 2021-08-30
            </Typography>
          </Container>
          <Typography variant="body1">
            Liscio Apps, LLC (the “Company”) is committed to maintaining robust
            privacy protections for its users. Our Privacy Policy (“Privacy
            Policy”) is designed to help you understand how we collect, use and
            safeguard the information you provide to us and to assist you in
            making informed decisions when using our Service.
          </Typography>
          <Typography variant="h4" component="h2" sx={{ marginY: 3 }}>
            Definitions
          </Typography>
          <Container sx={{ marginY: 3 }}>
            <Typography variant="body1">
              For purposes of this agreement:
              <ul>
                <li>
                  "Site" refers to the Company’s website, which can be accessed
                  at https://getquizme.com, https://app.getquizme.com or
                  http://liscioapps.com.
                </li>
                <li>
                  "Service" refers to the Company’s services accessed via the
                  Site, in which users can sign in, create, edit, and access
                  decks. Learn using the quizMe platform.
                </li>
                <li>
                  The terms "we,"" "us,"" "Company" and "our" refer to Liscio
                  Apps, LLC.
                </li>
                <li>
                  The terms "You", "customer" or "licensee" refers to you, as a
                  user of our Site or our Service.
                </li>
              </ul>
            </Typography>
          </Container>

          <Typography variant="h4" component="h2">
            Terms of Service
          </Typography>
          <Container sx={{ marginY: 3 }}>
            <Typography variant="body1">
              By accessing our Site or our Service, you accept our Privacy
              Policy and Terms of Use (found here: &nbsp;
              <Link to="/terms">https://getquizme.com/terms</Link>
              ), and you consent to our collection, storage, use and disclosure
              of your Personal Information as described in this Privacy Policy.
            </Typography>
          </Container>

          <Typography variant="h4" component="h2">
            Information We Collect
          </Typography>
          <Container sx={{ marginY: 3 }}>
            <Typography variant="body1">
              We collect “Non-Personal Information” and “Personal Information.”
              Non-Personal Information includes information that cannot be used
              to personally identify you, such as anonymous usage data, general
              demographic information we may collect, referring/exit pages and
              URLs, platform types, preferences you submit and preferences that
              are generated based on the data you submit and the number of
              clicks. Personal Information includes your email, name and other
              contact information, which you submit to us through the
              registration process at the Site.
            </Typography>

            <Typography variant="h5" component="h3" sx={{ marginY: 3 }}>
              1. Information collected via Technology
            </Typography>

            <Typography variant="body1">
              <p>
                To activate the Service you do not need to submit any Personal
                Information other than your email address. To use the Service
                thereafter, you do not need to submit further Personal
                Information.
              </p>

              <p>
                However, in an effort to improve the quality of the Service, we
                track information provided to us by your browser or by our
                software application when you view or use the Service, such as
                the website you came from (known as the “referring URL”), the
                type of browser you use, the device from which you connected to
                the Service, the time and date of access, and other information
                that does not personally identify you. We track this information
                using cookies and/or Google Analytics, or small text files which
                include an anonymous unique identifier. Cookies are sent to a
                user’s browser from our servers and are stored on the user’s
                computer hard drive.
              </p>

              <p>
                Sending a cookie to a user’s browser enables us to collect
                Non-Personal information about that user and keep a record of
                the user’s preferences when utilizing our services, both on an
                individual and aggregate basis. For example, the Company may use
                cookies to collect the following information:
              </p>

              <ul>
                <li>Your login session information to keep you logged in</li>
                <li>How you use the Service (how often, click paths, etc.)</li>
                <li>Other details about your use of the Service</li>
                <li>
                  The Company may use both persistent and session cookies;
                  persistent cookies remain on your computer after you close
                  your session and until you delete them, while session cookies
                  expire when you close your browser. For example, we store a
                  persistent cookie to track login session information.
                </li>
              </ul>
            </Typography>

            <Typography variant="h5" component="h3" sx={{ marginY: 3 }}>
              2. Information you provide us by registering for an account
            </Typography>

            <Typography variant="body1">
              In addition to the information provided automatically by your
              browser when you visit the Site, to become a subscriber to the
              Service you will need to create a personal profile. You can create
              a profile by registering with the Service and entering your email
              address and creating a user name and a password. By registering,
              you are authorizing us to collect, store and use your email
              address in accordance with this Privacy Policy.
            </Typography>

            <Typography variant="h5" component="h3" sx={{ marginY: 3 }}>
              3. Children’s Privacy
            </Typography>

            <Typography variant="body1">
              The Site and the Service are not directed to anyone under the age
              of 13. The Site does not knowingly collect or solicit information
              from anyone under the age of 13, or allow anyone under the age of
              13 to sign up for the Service. In the event that we learn that we
              have gathered personal information from anyone under the age of 13
              without the consent of a parent or guardian, we will delete that
              information as soon as possible. If you believe we have collected
              such information, please contact us at{" "}
              <a href="mailto:support@liscioapps.com">support@liscioapps.com</a>
              .
            </Typography>
          </Container>

          <Typography variant="h4" component="h2">
            How We Use and Share Information
          </Typography>
          <Container sx={{ marginY: 3 }}>
            <Typography variant="h5" component="h3" sx={{ marginY: 3 }}>
              Personal Information:
            </Typography>

            <Typography variant="body1">
              <p>
                We do not sell, trade, rent or otherwise share for marketing
                purposes your Personal Information with third parties without
                your consent. We do share Personal Information with vendors who
                are performing services for the Company, such as the servers for
                our email communications who are provided access to the user’s
                email address for purposes of sending emails from us. Those
                vendors use your Personal Information only at our direction and
                in accordance with our Privacy Policy.
              </p>

              <p>
                In general, the Personal Information you provide to us is used
                to help us communicate with you. For example, we use Personal
                Information to contact users in response to questions, solicit
                feedback from users, provide technical support, and inform users
                about promotional offers.
              </p>

              <p>
                We may share Personal Information with outside parties if we
                have a good-faith belief that access, use, preservation or
                disclosure of the information is reasonably necessary to meet
                any applicable legal process or enforceable governmental
                request; to enforce applicable Terms of Service, including
                investigation of potential violations; address fraud, security
                or technical concerns; or to protect against harm to the rights,
                property, or safety of our users or the public as required or
                permitted by law.
              </p>
            </Typography>

            <Typography variant="h5" component="h3" sx={{ marginY: 3 }}>
              Non-Personal Information:
            </Typography>

            <Typography variant="body1">
              <p>
                In general, we use Non-Personal Information to help us improve
                the Service and customize the user experience. We also aggregate
                Non-Personal Information in order to track trends and analyze
                use patterns on the Site. This Privacy Policy does not limit in
                any way our use or disclosure of Non-Personal Information and we
                reserve the right to use and disclose such Non-Personal
                Information to our partners, advertisers and other third parties
                at our discretion.
              </p>

              <p>
                In the event we undergo a business transaction such as a merger,
                acquisition by another company, or sale of all or a portion of
                our assets, your Personal Information may be among the assets
                transferred. You acknowledge and consent that such transfers may
                occur and are permitted by this Privacy Policy, and that any
                acquirer of our assets may continue to process your Personal
                Information as set forth in this Privacy Policy. If our
                information practices change at any time in the future, we will
                post the policy changes to the Site so that you may opt out of
                the new information practices. We suggest that you check the
                Site periodically if you are concerned about how your
                information is used.
              </p>
            </Typography>
          </Container>

          <Typography variant="h4" component="h2">
            Third Parties Involved
          </Typography>
          <Container sx={{ marginY: 3 }}>
            <Typography variant="body1">
              <ul>
                <li>
                  <strong>Web Hosting</strong>: Netlify is used as our main
                  hosting provider. You can view their privacy policy here:
                  https://www.netlify.com/privacy/.
                </li>
                <li>
                  Additional Web Services
                  <ul>
                    <li>
                      <strong>AWS</strong>: Additional web services are provided
                      by Amazon AWS. Their privacy policy is available here:
                      https://aws.amazon.com/privacy/.
                    </li>
                    <li>
                      <strong>GCP</strong>: Additional web services are provided
                      by GCP. Their privacy policy is available here:
                      https://cloud.google.com/security/privacy/.
                    </li>
                    <li>
                      <strong>Heroku</strong>: Additional web services are
                      provided by Heroku. Their privacy policy is available
                      here: https://www.heroku.com/policy/privacy.
                    </li>
                    <li>
                      <strong>Datadog</strong>: Additional web services are
                      provided by Datadog. Their privacy policy is available
                      here: https://www.datadoghq.com/legal/privacy/
                    </li>
                    <li>
                      <strong>Google Analytics</strong>: Additinal web services
                      are provided by Google Analytics. Their privacy policy is
                      availabe here: https://policies.google.com/privacy?hl=en
                    </li>
                    <li>
                      <strong>Fathom Analytics</strong>: Additinal web services
                      are provided by Fathom Analytics. Their privacy policy is
                      availabe here: https://usefathom.com/privacy
                    </li>
                    <li>
                      <strong>Payment</strong>: processing All payment
                      processing is done through Stripe. Their privacy policy is
                      available here: https://stripe.com/privacy.
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Container>

          <Typography variant="h4" component="h2">
            How We Protect Information
          </Typography>
          <Container sx={{ marginY: 3 }}>
            <Typography variant="body1">
              We implement security measures designed to protect your
              information from unauthorized access. Your account is protected by
              your account password or access to the SSO account you use to log
              into our Service and we urge you to take steps to keep your
              personal information safe by not disclosing your password and by
              logging out of your account after each use. We further protect
              your information from potential security breaches by implementing
              certain technological security measures including encryption,
              firewalls and secure socket layer technology. However, these
              measures do not guarantee that your information will not be
              accessed, disclosed, altered or destroyed by breach of such
              firewalls and secure server software. By using our Service, you
              acknowledge that you understand and agree to assume these risks.
            </Typography>
          </Container>

          <Typography variant="h4" component="h2">
            Your Data Protection Rights
          </Typography>
          <Container sx={{ marginY: 3 }}>
            <Typography variant="body1">
              Our Company would like to make sure you are fully aware of all of
              your data protection rights. Every user is entitled to the
              following:
              <ul>
                <li>
                  The right to access – You have the right to request Our
                  Company for copies of your personal data. We may charge you a
                  small fee for this service.
                </li>
                <li>
                  The right to rectification – You have the right to request
                  that Our Company correct any information you believe is
                  inaccurate. You also have the right to request Our Company to
                  complete the information you believe is incomplete.
                </li>
                <li>
                  The right to erasure – You have the right to request that Our
                  Company erase your personal data, under certain conditions.
                </li>
                <li>
                  The right to restrict processing – You have the right to
                  request that Our Company restrict the processing of your
                  personal data, under certain conditions.
                </li>
                <li>
                  The right to object to processing – You have the right to
                  object to Our Company’s processing of your personal data,
                  under certain conditions.
                </li>
                <li>
                  The right to data portability – You have the right to request
                  that Our Company transfer the data that we have collected to
                  another organization, or directly to you, under certain
                  conditions.
                </li>
              </ul>
              <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us at our email:{" "}
                <a href="support@liscioapps.com">support@liscioapps.com</a>
                Or write to us:
              </p>
              <blockquote>
                Liscio Apps, LLC <br />
                1505 Rochester Ct <br />
                Crofton, MD 21114
              </blockquote>
            </Typography>
          </Container>

          <Typography variant="h4" component="h2">
            Links to Other Websites
          </Typography>
          <Container sx={{ marginY: 3 }}>
            <Typography variant="body1">
              As part of the Service, we may provide links to or compatibility
              with other websites or applications. However, we are not
              responsible for the privacy practices employed by those websites
              or the information or content they contain. This Privacy Policy
              applies solely to information collected by us through the Site and
              the Service. Therefore, this Privacy Policy does not apply to your
              use of a third-party website accessed by selecting a link on our
              Site or via our Service. To the extent that you access or use the
              Service through or on another website or application, then the
              privacy policy of that other website or application will apply to
              your access or use of that site or application. We encourage our
              users to read the privacy statements of other websites before
              proceeding to use them.
            </Typography>
          </Container>

          <Typography variant="h4" component="h2">
            Changes to Our Privacy Policy
          </Typography>
          <Container sx={{ marginY: 3 }}>
            <Typography variant="body1">
              The Company reserves the right to change this policy and our Terms
              of Service at any time. We will notify you of significant changes
              to our Privacy Policy by sending a notice to the primary email
              address specified in your account or by placing a prominent notice
              on our site. Significant changes will go into effect 30 days
              following such notification. Non-material changes or
              clarifications will take effect immediately. You should
              periodically check the Site and this privacy page for updates.
            </Typography>
          </Container>

          <Typography variant="h4" component="h2">
            Contact Us
          </Typography>
          <Container sx={{ marginY: 3 }}>
            <Typography variant="body1">
              If you have any questions regarding this Privacy Policy or the
              practices of this Site, please contact us by sending an email to{" "}
              <a href="mailto:support@liscioapps.com">support@liscioapps.com</a>
              .
            </Typography>
          </Container>
        </Container>

        <Box>
          <Footer />
        </Box>
      </Box>
    </React.Fragment>
  );
}
