import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppSettingsState {
  theme: "light" | "dark" | "system";
  drawerOpen: boolean;
}

const initialState: AppSettingsState = {
  theme: "dark",
  drawerOpen: true,
};

export const appSettingsSlice = createSlice({
  name: "AppSettings",
  initialState,
  reducers: {
    setTheme: (
      state: AppSettingsState,
      action: PayloadAction<"light" | "dark" | "system">
    ) => {
      state.theme = action.payload;
    },
    setDrawerOpen: (
      state: AppSettingsState,
      action: PayloadAction<boolean>
    ) => {
      state.drawerOpen = action.payload;
    },
  },
});

export const { setTheme, setDrawerOpen } = appSettingsSlice.actions;
export const appSettingsReducer = appSettingsSlice.reducer;
