import React from "react";
import { Container, Box, Typography, Link, Button, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function Footer() {
  const history = useHistory();

  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Container>&nbsp;</Container>
      <Container maxWidth="sm">
        <Typography variant="body1">Quiz Me online flashcards</Typography>
        <Typography variant="body2" color="text.secondary">
          {"Made with 💜 in Maryland, Pennsylvania, and Vietnam"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {"Copyright © "}
          <Link color="inherit" href="https://liscioapps.com/">
            Liscio Apps, LLC
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Container>
      <Container>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              onClick={() => {
                history.push("/terms");
              }}
            >
              Terms
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                history.push("/privacy");
              }}
            >
              Privacy Policy
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
