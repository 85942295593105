import { useAppDispatch } from "../../hooks/useStore";
import { setAppbarTitle } from "../../reducers/appTitlesReducer";

interface WithAppbarProps {
  title: string;
  children: any;
}

const WithAppbarTitle = (props: WithAppbarProps) => {
  const dispatch = useAppDispatch();
  dispatch(setAppbarTitle(props.title || "QuizMe"));
  return props.children;
};

export default WithAppbarTitle;
