import {
  AuthService,
  logout,
  quizMeConfig,
  useSubscriptionsService,
  UserSubscriptionModel,
  SubscriptionPlan,
  HttpClientService,
  useDateTimeFormatter,
  useUserNotificationSettings,
  UserNotificationSettingModel,
  useUserService,
} from "@liscioapps/quizme-client-library";
import {
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PlanCard } from "./PlanCard";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { setTheme } from "../../reducers/appSettingsReducer";
import { DarkModeSwitch } from "./DarkModeSwitch";
import packageJson from "../../../package.json";
import { ConfirmDeleteAccountDialog } from "./ConfirmDeleteAccountDialog";

// import clientPackageJson from "@liscioapps/quizme-client-library/package.json";

interface SubscriptionState {
  changePending: boolean;
  timeCheckedForUpdates: number;
  currentSubscription?: UserSubscriptionModel;
  changeFailedMessage?: string;
  changeSucceededMessage?: string;
  plans: SubscriptionPlan[];
}

const SettingsScreen = () => {
  const { toReadableDayPart } = useDateTimeFormatter();
  let history = useHistory();
  const authService = AuthService.Instance;
  const dispatch = useAppDispatch();
  const theme = useAppSelector((s) => s.settings.theme);
  const [loading, setLoading] = useState(false);

  const subscriptionExpired = useAppSelector(
    (s) => s.auth.subscriptionIsExpired
  );

  const [isDarkMode, setDarkMode] = useState(theme === "dark");
  const [useSystemTheme, setUseSystemTheme] = useState(false);
  const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false);

  const { getStripeCheckoutLink, getStripePortalLink, getUserSubscription } =
    useSubscriptionsService();

  const [subscriptionState, setSubscriptionState] = useState<SubscriptionState>(
    {
      changePending: false,
      timeCheckedForUpdates: 0,
      plans: [],
    }
  );

  const {
    getUserDueDeckNotificationSettings,
    updateUserDueDeckNotificationSettings,
  } = useUserNotificationSettings();
  const [dueDeckNotificationSettings, setDueDeckNotificationSettings] =
    useState<UserNotificationSettingModel>();

  const { deleteCurrentUserAccount } = useUserService();

  const getProvider = (): "apple" | "stripe" | "google" | "none" => {
    if (subscriptionState.currentSubscription?.appleStoreProductId) {
      return "apple";
    } else if (subscriptionState.currentSubscription?.googlePlayProductId) {
      return "google";
    } else if (subscriptionState.currentSubscription?.stripeSubscriptionKey) {
      return "stripe";
    }
    return "none";
  };

  const loadSubscriptionInfo = async () => {
    const response = await getUserSubscription();
    setSubscriptionState((s) => {
      return { ...s, currentSubscription: response };
    });
  };

  const pollForSubscriptionUpdate = async () => {
    if (getProvider() === "stripe") {
      return;
    } else {
      const response = await getUserSubscription();
      if (!response.stripeSubscriptionKey) {
        // we didn't get the stripe update yet
        let timesChecked = 0;
        setSubscriptionState((s) => {
          timesChecked = s.timeCheckedForUpdates + 1;
          return { ...s, timeCheckedForUpdates: timesChecked };
        });
        if (timesChecked > 30) {
          return;
        } else {
          setTimeout(() => pollForSubscriptionUpdate(), 2000);
        }
      } else {
        setSubscriptionState((s) => {
          return {
            ...s,
            currentSubscription: response,
            changePending: false,
            changeSucceededMessage: undefined,
            changeFailedMessage: undefined,
          };
        });
      }
    }
  };

  const loadSubscriptionPlans = async () => {
    const response = await HttpClientService.Instance.get<SubscriptionPlan[]>(
      `${quizMeConfig.apiEndpointUrl}/api/subscriptionPlan`
    );
    const stripePlans = response.data.filter((p) => !!p.stripeSubscriptionKey);
    setSubscriptionState((s) => {
      return { ...s, plans: stripePlans };
    });
  };

  const loadNotificationSettings = async () => {
    const settings = await getUserDueDeckNotificationSettings();
    setDueDeckNotificationSettings(settings);
  };

  useEffect(() => {
    loadNotificationSettings();
    loadSubscriptionPlans();

    const query = new URLSearchParams(window.location.search);

    if (query.get("subscribeSuccess")) {
      query.delete("subscribeSuccess");
      setSubscriptionState((s) => {
        return {
          ...s,
          changePending: true,
          changeSucceededMessage: "Order placed. Confirming....",
        };
      });
      pollForSubscriptionUpdate();
      return;
    }
    if (query.get("subscribeCanceled")) {
      query.delete("subscribeCanceled");
      setSubscriptionState((s) => {
        return {
          ...s,
          changeFailedMessage: "Order canceled.",
        };
      });
    }

    loadSubscriptionInfo();
  }, []);

  const userDidClickManageSubscription = async () => {
    const link = await getStripePortalLink();
    window.location.href = link;
  };

  const userDidClickManageAppleSubscription = async () => {
    const link = "https://apps.apple.com/account/subscriptions";
    window.open(link, "_blank");
  };

  const userDidClickSubscribe = async (stripePriceId: string) => {
    const link = await getStripeCheckoutLink(stripePriceId);
    window.location.href = link;
  };

  const onLogoutPressed = async () => {
    setLoading(true);
    await authService.logout();
    dispatch(logout());
    setLoading(false);
    history.push("/login");
  };

  const onDeleteAccountConfirm = async () => {
    await deleteCurrentUserAccount();
    await authService.logout();
    dispatch(logout());
    alert("Your account has been deleted");
    history.push("/login");
  };

  const onToggleDarkMode = (value: boolean) => {
    setDarkMode(value);
    const targetTheme = value ? "dark" : "light";
    quizMeConfig.storageService?.store("appTheme", targetTheme);
    dispatch(setTheme(targetTheme));
  };

  const updateDueDeckNotifications = async (
    type: "mobile" | "email",
    enabled: boolean
  ) => {
    const newSettings = { ...dueDeckNotificationSettings! };
    if (type === "mobile") {
      newSettings.receiveViaPushNotification = enabled;
    }
    if (type === "email") {
      newSettings.receiveViaEmail = enabled;
    }
    try {
      await updateUserDueDeckNotificationSettings(newSettings);
      setDueDeckNotificationSettings(newSettings);
    } catch (e) {
      // TODO: handle errors better
      alert("Error updating notification settings!");
      console.error(e);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ padding: 3 }}>
        <Typography variant="h5">Preferences</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <DarkModeSwitch
                sx={{ m: 1 }}
                checked={isDarkMode}
                onChange={() => onToggleDarkMode(!isDarkMode)}
              />
            }
            label="Theme"
          />
        </FormGroup>
      </Paper>
      <Paper sx={{ padding: 3, marginTop: 2 }}>
        <Typography variant="h5">Due Deck Notifications</Typography>
        <Typography variant="caption">
          Sent when decks are due to be reviewed
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                sx={{ m: 1 }}
                checked={
                  dueDeckNotificationSettings?.receiveViaPushNotification
                }
                onChange={(event, checked) =>
                  updateDueDeckNotifications("mobile", checked)
                }
              />
            }
            label="Mobile"
          />
          <FormControlLabel
            control={
              <Switch
                sx={{ m: 1 }}
                checked={dueDeckNotificationSettings?.receiveViaEmail}
                onChange={(event, checked) =>
                  updateDueDeckNotifications("email", checked)
                }
              />
            }
            label="Email"
          />
        </FormGroup>
      </Paper>

      {/* signup options */}
      {getProvider() === "none" &&
        !subscriptionState.changePending &&
        subscriptionState.currentSubscription?.subscriptionPlanId && (
          <Paper sx={{ padding: 3, marginTop: 2 }}>
            <Typography variant="h5">Subscribe</Typography>
            <Grid
              container
              justifyContent={"space-between"}
              spacing={3}
              marginTop={0.5}
            >
              <Grid item sm={12} md={4}>
                <PlanCard
                  isSubscribed
                  title="Free"
                  features={["3 decks", "10 cards per deck"]}
                  price="$0"
                ></PlanCard>
              </Grid>
              <Grid item sm={12} md={4}>
                <PlanCard
                  title="Yearly"
                  onClickBuy={() => {
                    const priceId = subscriptionState.plans.find(
                      (_) => _.recurringIntervalUnit === 3
                    )?.stripeSubscriptionKey;
                    if (priceId) {
                      userDidClickSubscribe(priceId!);
                    }
                  }}
                  features={[
                    "Unlimited Decks",
                    "Unlimited Cards",
                    "Notifications",
                  ]}
                  price="$19.99"
                ></PlanCard>
              </Grid>{" "}
              <Grid item sm={12} md={4}>
                <PlanCard
                  title="Monthly"
                  onClickBuy={() => {
                    const priceId = subscriptionState.plans.find(
                      (_) => _.recurringIntervalUnit === 2
                    )?.stripeSubscriptionKey;
                    if (priceId) {
                      userDidClickSubscribe(priceId!);
                    }
                  }}
                  features={[
                    "Unlimited Decks",
                    "Unlimited Cards",
                    "Notifications",
                  ]}
                  price="$2.99"
                ></PlanCard>
              </Grid>
            </Grid>
          </Paper>
        )}

      {/* manage current subscription */}
      {getProvider() !== "none" && (
        <Paper sx={{ padding: 3, marginTop: 2 }}>
          <Typography variant="h5">Subscription</Typography>
          <Grid
            container
            spacing={3}
            marginTop={0.5}
            justifyItems="center"
            alignItems={"center"}
          >
            {!subscriptionState.currentSubscription?.subscriptionPlanId && (
              <Grid item>
                <CircularProgress size={24} />{" "}
              </Grid>
            )}
            {!subscriptionState.changePending &&
              subscriptionState.currentSubscription?.subscriptionPlanId &&
              getProvider() === "stripe" && (
                <>
                  <Grid item>
                    <Typography variant="body1">
                      {
                        subscriptionState.currentSubscription
                          ?.subscriptionPlanName
                      }
                    </Typography>
                  </Grid>
                  <Grid item sx={{ paddingLeft: "8px !important" }}>
                    <Typography
                      variant="body1"
                      color={subscriptionExpired ? "red" : "green"}
                    >
                      {`(`}
                      {`${subscriptionExpired ? "expired " : "renews "}`}
                      {subscriptionState.currentSubscription?.expiration &&
                        toReadableDayPart(
                          new Date(
                            subscriptionState.currentSubscription?.expiration!
                          )
                        )}
                      {`)`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button onClick={userDidClickManageSubscription}>
                      Manage
                    </Button>
                  </Grid>
                </>
              )}

            {!subscriptionState.changePending &&
              subscriptionState.currentSubscription?.subscriptionPlanId &&
              getProvider() === "apple" && (
                <>
                  <Grid item>
                    <Typography variant="body1">
                      {`${subscriptionState.currentSubscription?.subscriptionPlanName} - managed through apple payments`}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ paddingLeft: "8px !important" }}>
                    <Typography
                      variant="body1"
                      color={subscriptionExpired ? "red" : "green"}
                    >
                      {`(`}
                      {`${subscriptionExpired ? "expired " : "renews "}`}
                      {subscriptionState.currentSubscription?.expiration &&
                        toReadableDayPart(
                          new Date(
                            subscriptionState.currentSubscription?.expiration!
                          )
                        )}
                      {`)`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button onClick={userDidClickManageAppleSubscription}>
                      Manage
                    </Button>
                  </Grid>
                </>
              )}

            {subscriptionState.changePending && (
              <>
                <Grid item>
                  <Typography variant="body1">
                    Subscription Finalizing...
                  </Typography>
                </Grid>
                <Grid item>
                  <CircularProgress size={24} />
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      )}

      <Paper sx={{ padding: 3, marginTop: 2 }}>
        <Typography variant="h5">Account management</Typography>
        <Button
          variant={"contained"}
          color={"error"}
          style={{ marginTop: 30 }}
          onClick={() => setShowDeleteAccountDialog(true)}
        >
          Delete Account
        </Button>
      </Paper>

      <Typography align="center">
        <Button sx={{ mt: 3 }} onClick={onLogoutPressed} variant={"outlined"}>
          Logout
        </Button>
      </Typography>
      <Typography align="center">
        <Typography variant="caption" align="center">
          Version: {packageJson.version} / Client Library Version:
          {/* {clientPackageJson.version} / Endpoint: */}
          Endpoint: {quizMeConfig.apiEndpointUrl}
        </Typography>
      </Typography>
      {/*  Dialogs */}
      <ConfirmDeleteAccountDialog
        open={showDeleteAccountDialog}
        onClose={() => setShowDeleteAccountDialog(false)}
        onDeleteConfirm={onDeleteAccountConfirm}
      />
    </Container>
  );
};

export default SettingsScreen;
