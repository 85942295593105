import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useState } from "react";

const CreateDeckDialogue = (props: {
  open: boolean;
  handleCancel: () => void;
  handleCreate: (title: string, description: string) => void;
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const clearState = () => {
    setTitle("");
    setDescription("");
  };

  const cancel = () => {
    clearState();
    props.handleCancel();
  };

  const create = () => {
    props.handleCreate(title, description);
  };

  return (
    <Dialog open={props.open} onClose={cancel}>
      <DialogTitle>Add Deck</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ mt: 2 }}
          autoFocus
          label="Title"
          fullWidth
          variant="outlined"
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
        />
        <TextField
          sx={{ mt: 2 }}
          label="Description"
          fullWidth
          variant="outlined"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={create} variant="contained">
          Create Deck
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CreateDeckDialogue;
