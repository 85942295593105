import React from "react";
import { useHistory } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Stack,
} from "@mui/material";

import AppHeaderBar from "../../components/AppBar";
import Footer from "../../components/Footer";
import HomepageImg from "../../assets/homepage.png";
import PhoneMockup from "../../assets/iphonemockup2.png";
import AppleAppStore from "../../assets/appleappstore.png";
import GooglePlaystore from "../../assets/googleplaystore.png";
import { Favorite, FileDownloadDone, PushPin } from "@mui/icons-material";

export default function LandingPage() {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="welcome-area">
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <CssBaseline />
          <AppHeaderBar />
          <Container
            component="main"
            sx={{
              flexGrow: 2,
            }}
          >
            <Grid container spacing={4} marginTop={8}>
              <Grid item md={9}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                  justifyItems="center"
                >
                  <Grid item md={7} xs={12}>
                    <Typography
                      component="h1"
                      variant="h2"
                      gutterBottom
                      color="white"
                    >
                      QuizMe
                    </Typography>
                    <Typography
                      component="h2"
                      variant="h4"
                      gutterBottom
                      color="white"
                    >
                      Learn simply anything
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <Button
                        size="large"
                        aria-describedby={id}
                        onClick={handleClick}
                        sx={{ padding: 1 }}
                      >
                        <img
                          src={AppleAppStore}
                          height="60"
                          alt="Download on the Apple App Store for iOS"
                        />
                      </Button>
                      <Button size="large" sx={{ padding: 1 }}>
                        <img
                          src={GooglePlaystore}
                          height="60"
                          alt="Download on the Google Play Store for Andriod"
                        />
                      </Button>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignContent="center"
                      alignItems="center"
                      justifyContent="center"
                      marginTop={4}
                    >
                      <span></span>
                      <Button
                        variant="contained"
                        onClick={() => history.push("/signup")}
                        size="large"
                      >
                        Sign up now
                      </Button>
                      <span></span>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack direction="row" alignItems="center"></Stack>
              </Grid>
              <Grid item md={3} display={{ xs: "none", md: "block" }}>
                <img
                  src={PhoneMockup}
                  alt="iPhone showing the quiz me application, spanish basics cards"
                />
              </Grid>
            </Grid>

            <Paper
              elevation={10}
              variant="elevation"
              sx={{
                padding: 4,
                marginY: 6,
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.primary.light
                    : theme.palette.primary.dark,
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <Typography
                    variant="h2"
                    color={(theme) => theme.palette.grey[900]}
                  >
                    Flashcards that actually work 🎉
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Card sx={{ my: 2 }}>
                    <CardContent>
                      <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <Box sx={{ mr: 1 }}>
                          <FileDownloadDone color="info" />
                        </Box>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          color={(theme) => theme.palette.info.main}
                        >
                          Easy to use online flashcards
                        </Typography>
                      </Box>

                      <Typography variant="body2" color="text.secondary">
                        If you can use flashcards, you can use quizMe. The
                        difference is: quizMe is backed by science...and doesn't
                        use any trees.
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ my: 2 }}>
                    <CardContent>
                      <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <Box sx={{ mr: 1 }}>
                          <PushPin color="info" />
                        </Box>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          color={(theme) => theme.palette.info.main}
                        >
                          Automated Reminders
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        Specially designed, personalized algorithms send you
                        reminders precisely when you need them - and never when
                        you don't!
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ my: 2 }}>
                    <CardContent>
                      <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <Box sx={{ mr: 1 }}>
                          <Favorite color="info" />
                        </Box>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          color={(theme) => theme.palette.info.main}
                        >
                          Easy on the wallet, too
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        Free to get started. And when you need more, just
                        $2.99/month. Cancel anytime.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Paper>

            <Grid
              container
              spacing={2}
              marginY={4}
              padding={4}
              sx={{
                borderRadius: "24px",
                backgroundColor: (theme) => theme.palette.secondary.dark,
              }}
            >
              <Grid item md={6}>
                <Typography
                  variant="h6"
                  color={(theme) => theme.palette.error.light}
                >
                  Unlock the power of Spaced Repetition
                </Typography>
                <Typography
                  variant="h2"
                  color={(theme) => theme.palette.grey[100]}
                >
                  Learn once, remember forever
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ my: 5 }}
                  color={(theme) => theme.palette.grey[100]}
                >
                  Spaced repetition has been known for years as the best way to
                  ensure long-term retention of information and knowledge.
                  <br />
                  <br />
                  You invest a lot of time and energy in learning - shouldn't
                  that investment pay dividends for years to come?
                  <br />
                  <br />
                  Enable your teams to learn anything quickly - but more
                  importantly, retain it forever.
                </Typography>
              </Grid>
              <Grid item md={6}>
                <img
                  src={HomepageImg}
                  alt="Woman sits at a laptop happily learning"
                  width="100%"
                />
              </Grid>
            </Grid>
          </Container>

          <Box>
            <Footer />
          </Box>
        </Box>
      </React.Fragment>
    </div>
  );
}
