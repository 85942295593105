import { useState } from "react";
import { QuizSnackbarState } from "src/components/QuizSnackbar";

export const useQuizSnackbar = () => {
  const [quizSnackbarProps, setQuizSnackbarProps] = useState<QuizSnackbarState>(
    {
      severity: "success",
      show: false,
      message: "",
    }
  );
  const showErrorMessage = (message: string) => {
    setQuizSnackbarProps({ show: true, message, severity: "error" });
  };

  const showSuccessMessage = (message: string) => {
    setQuizSnackbarProps({ show: true, message, severity: "success" });
  };
  return {
    quizSnackbarProps,
    setQuizSnackbarProps,
    showErrorMessage,
    showSuccessMessage,
  };
};
