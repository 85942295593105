import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  CardModel,
  useDeckService,
  useDateTimeFormatter,
  useCardService,
  addCardToCurrentDeck,
  updateCardInCurrentDeck,
  setCurrentDeck,
  updateCurrentDeckDescription,
  clearCurrentDeck,
  deleteCardFromCurrentDeck,
  PublicDeckCRUDService,
} from "@liscioapps/quizme-client-library";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import {
  Container,
  Paper,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Card,
  CardContent,
  Typography,
  styled,
  Fab,
  Box,
  Menu,
  IconButton,
  OutlinedInput,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  linearProgressClasses,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardEdit from "../../components/CardEdit";
import { useQuizSnackbar } from "../../hooks/useQuizSnackbar";
import { QuizSnackbar } from "../../components/QuizSnackbar";
import { SubscsribePromptDialog } from "../../components/SubscribePromptDialog";
import { useKeyPress } from "../../hooks/useKeypress";
import { canAddCardsSelector } from "../../selectors/canAddItemsSelector";
import { FileUpload } from "./FileUpload";
import Link from "@mui/material/Link";

export default function DeckDetails() {
  const history = useHistory();
  let { deckId } = useParams<{ deckId: string }>();

  const publicDeckService = PublicDeckCRUDService.Instance;

  const drawerOpen = useAppSelector((s) => s.settings.drawerOpen);

  const canAddCards = useAppSelector(canAddCardsSelector);

  const dispatcher = useAppDispatch();

  const { getDeckDetails, updateDeck, deleteDeck } = useDeckService(dispatcher);
  const {
    // isLoading: isCardLoading,
    getList: getCardList,
    createCard,
    updateCard,
    deleteCard,
  } = useCardService(dispatcher);

  const currentDeck = useAppSelector((s) => s.selectedDeck.currentDeck);
  const [isEditing, setIsEditing] = useState(false);
  const [showImportDialog, setShowImportDialog] = useState(false);

  // local state
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  // this is so we can do the cool animation thing
  const [progress, setProgress] = useState(0);

  const {
    quizSnackbarProps,
    setQuizSnackbarProps,
    showErrorMessage,
    showSuccessMessage,
  } = useQuizSnackbar();

  const [showDeleteDialogue, setShowDeleteDialogue] = useState(false);
  const [showCardEdit, setShowCardEdit] = useState(false);
  const [showSubscribeDialog, setShowSubscribeDialog] = useState(false);
  const [selectedCard, setSelectedCard] = useState<CardModel>({} as CardModel);

  const handleDeleteDeckCancel = () => {
    setShowDeleteDialogue(false);
  };

  const showAddCardDialogue = () => {
    if (canAddCards) {
      setSelectedCard({} as CardModel);
      setShowCardEdit(true);
    } else {
      setShowSubscribeDialog(true);
    }
  };

  useKeyPress(["KeyA"], showAddCardDialogue, !showCardEdit && !isEditing);

  const showEditCardDialogue = (card: CardModel) => {
    setShowCardEdit(true);
    setSelectedCard(card);
  };

  const handleCloseCardEdit = () => {
    setSelectedCard({} as CardModel);
    setShowCardEdit(false);
  };

  const handleSaveCard = async (
    question: string,
    answer: string,
    createAnother: boolean
  ) => {
    const newCard: CardModel = {
      ...selectedCard,
      deckId: currentDeck.id!,
      questionContent: question,
      questionAnswer: answer,
    };

    try {
      if (newCard.id) {
        await updateCard(newCard.id, newCard);
        dispatcher(updateCardInCurrentDeck(newCard));
      } else {
        const { entityId } = await createCard(newCard);
        dispatcher(addCardToCurrentDeck({ ...newCard, id: entityId }));
      }
      showSuccessMessage("Card Saved");
      if (!createAnother) {
        setShowCardEdit(false);
      }
      setSelectedCard({} as CardModel);
    } catch {
      // TODO: handle errors
    }
  };

  const publishDeck = async () => {
    try {
      await publicDeckService.requestPublish(currentDeck?.id!);
      showSuccessMessage("Deck published!");
    } catch (e: any) {
      showErrorMessage("Error publishing!");
    }
  };

  const handleDeleteCard = async (cardId: number) => {
    await deleteCard(cardId);
    dispatcher(deleteCardFromCurrentDeck(cardId));
    setSelectedCard({} as CardModel);
    setShowCardEdit(false);
    showSuccessMessage("Card Deleted");
  };

  const handleDeleteDeckConfirm = async () => {
    await deleteDeck(currentDeck.id!);
    dispatcher(clearCurrentDeck());
    history.push("/app/decks");
  };

  const reviewCards = (mode: "due" | "all") => {
    history.push(`/app/review?deckId=${deckId}&learnMode=${mode}`);
  };

  const reloadDeck = async () => {
    const [deck, cards] = await Promise.all([
      getDeckDetails(Number(deckId)),
      getCardList(Number(deckId)),
    ]);

    setProgress(deck.progressPercentage || 0);
    dispatcher(
      setCurrentDeck({ ...currentDeck, dueCardCount: deck.dueCardCount, cards })
    );
  };

  useEffect(() => {
    const loadDeck = async () => {
      const [deck, cards] = await Promise.all([
        getDeckDetails(Number(deckId)),
        getCardList(Number(deckId)),
      ]);
      setTitle(deck.title);
      setDescription(deck.description);
      setProgress(deck.progressPercentage || 0);
      dispatcher(setCurrentDeck({ ...deck, cards }));
    };
    loadDeck();

    return () => {
      dispatcher(clearCurrentDeck());
    };
  }, []);

  const CustomFab = styled(Fab)(({ theme }) => ({
    position: "fixed",
    [theme.breakpoints.up("xs")]: {
      bottom: theme.spacing(5),
      right: theme.spacing(6),
    },
    [theme.breakpoints.up("md")]: {
      bottom: theme.spacing(8),
      right: theme.spacing(8),
    },
    [theme.breakpoints.up("lg")]: {
      bottom: theme.spacing(8),
      right: drawerOpen
        ? "calc((100vw - 1100px)/2 )"
        : "calc((100vw - 940px) /2 )",
    },
  }));

  function ActionsMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const onClickDelete = () => {
      handleClose();
      setShowDeleteDialogue(true);
    };

    const userDidClickImportCards = () => {
      setShowImportDialog(true);
    };

    return (
      <div>
        <IconButton
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => setIsEditing(true)}>Edit</MenuItem>
          <MenuItem onClick={onClickDelete}>Delete</MenuItem>
          <MenuItem onClick={publishDeck}>Publish Deck</MenuItem>
          <MenuItem onClick={userDidClickImportCards}>Import</MenuItem>
        </Menu>
      </div>
    );
  }

  const saveDeck = async () => {
    const newDeckInfo = { ...currentDeck, title, description };
    await updateDeck(newDeckInfo.id!, newDeckInfo);
    dispatcher(
      updateCurrentDeckDescription({
        title: newDeckInfo.title,
        description: newDeckInfo.description,
      })
    );
    setIsEditing(false);
    showSuccessMessage("Deck Saved");
  };

  const cancelEdit = () => {
    setTitle(currentDeck.title);
    setDescription(currentDeck.description);
    setIsEditing(false);
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 400 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.secondary.main,
    },
  }));

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      {!isEditing ? (
        <React.Fragment>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h3" sx={{ flex: 1 }}>
              {currentDeck?.title}
            </Typography>
            <ActionsMenu />
          </Box>
          <Typography variant="h5">{currentDeck?.description}</Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ display: "flex" }}>
            <TextField
              sx={{ flex: 1 }}
              label="Title"
              value={title}
              onChange={(event) => {
                // console.log(event.target.value);
                setTitle(event.target.value);
              }}
            />
            <React.Fragment>
              <Button onClick={saveDeck}>Save</Button>
              <Button onClick={cancelEdit}>Cancel</Button>
            </React.Fragment>
          </Box>
          <Box sx={{ display: "flex" }}>
            <FormControl variant="outlined" sx={{ flex: 1, mt: 2 }}>
              <InputLabel htmlFor="component-outlined">Description</InputLabel>
              <OutlinedInput
                label="Description"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </FormControl>
          </Box>
        </React.Fragment>
      )}
      <Box sx={{ mt: 2, mb: 4 }}>
        <Button
          variant="contained"
          onClick={() => reviewCards("due")}
          disabled={currentDeck.dueCardCount === 0}
          sx={{ mr: 2 }}
        >
          {currentDeck?.dueCardCount
            ? `Review ${currentDeck.dueCardCount} Due`
            : `Review Due`}
        </Button>
        <Button
          variant="outlined"
          onClick={() => reviewCards("all")}
          disabled={currentDeck.cards?.length === 0}
        >
          Review All
        </Button>
        <Tooltip title={`Total deck progress ${progress}/100`} arrow>
          <BorderLinearProgress
            sx={{ marginTop: 2 }}
            variant="determinate"
            value={progress}
          ></BorderLinearProgress>
        </Tooltip>
      </Box>
      <Paper sx={{ mt: 2, p: 4 }}>
        {currentDeck.id && !currentDeck.cards?.length && (
          <Typography textAlign={"center"} sx={{ marginBottom: 3 }}>
            Click the add button to add your first card, or{" "}
            <Link
              component="button"
              variant="body1"
              onClick={() => {
                setShowImportDialog(true);
              }}
            >
              import from file
            </Link>
          </Typography>
        )}

        <CardsList
          cards={currentDeck.cards || []}
          selectCardAction={(card) => showEditCardDialogue(card)}
        ></CardsList>
      </Paper>
      <CustomFab color="secondary" onClick={showAddCardDialogue}>
        <AddIcon />
      </CustomFab>
      <SubscsribePromptDialog
        onClose={() => setShowSubscribeDialog(false)}
        onSubscribeClicked={() => history.push(`/app/settings`)}
        open={showSubscribeDialog}
        type={"cards"}
      ></SubscsribePromptDialog>
      <CardEdit
        show={showCardEdit}
        card={selectedCard}
        handleSaveCard={handleSaveCard}
        handleClose={handleCloseCardEdit}
        handleDeleteCard={handleDeleteCard}
      ></CardEdit>
      <QuizSnackbar
        state={quizSnackbarProps}
        setState={setQuizSnackbarProps}
      ></QuizSnackbar>
      {showImportDialog && (
        <FileUpload
          deckId={Number(deckId)}
          onClose={() => {
            setShowImportDialog(false);
          }}
          show={showImportDialog}
          onImportSuccess={() => {
            reloadDeck();
          }}
        />
      )}
      <ConfirmDeleteDialogue
        open={showDeleteDialogue}
        handleCancel={handleDeleteDeckCancel}
        handleConfirm={handleDeleteDeckConfirm}
      ></ConfirmDeleteDialogue>
    </Container>
  );
}

const QuizCard: React.FC<{
  card: CardModel;
  onClick: (card: CardModel) => void;
}> = ({ card, onClick }) => {
  const { toReadableDayPart } = useDateTimeFormatter();

  return (
    <Card onClick={() => onClick(card)}>
      <CardContent>
        <Typography variant="h5" noWrap={true} textOverflow="ellipsis">
          {card.questionContent}
        </Typography>
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {props.card.dueDate}
        </Typography> */}
        <Typography variant="body2">
          Due:{" "}
          {toReadableDayPart(
            card.dueDate ? new Date(card.dueDate) : new Date()
          )}
        </Typography>
      </CardContent>
    </Card>
  );
};

const CardsList = React.memo<{
  cards: CardModel[];
  selectCardAction: (card: CardModel) => void;
}>(
  ({ cards, selectCardAction }) => {
    return (
      <Grid container spacing={3}>
        {cards.map((card) => (
          <Grid item xs={12} md={6} key={card.id}>
            <QuizCard card={card} onClick={selectCardAction} />
          </Grid>
        ))}
      </Grid>
    );
  },
  (prev, next) => {
    return prev.cards === next.cards;
  }
);

const ConfirmDeleteDialogue = (props: {
  open: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
}) => (
  <Dialog open={props.open} onClose={props.handleCancel}>
    <DialogTitle id="alert-dialog-title">Delete Deck?</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        This will delete all cards and answer history!
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.handleCancel} autoFocus>
        Cancel
      </Button>
      <Button onClick={props.handleConfirm}>Delete</Button>
    </DialogActions>
  </Dialog>
);
