import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  PublicCardModel,
  fetchSelectedPublicDeck,
  addPublicDeckToMyDecks,
  requestApproveSelectedPublicDeck,
  requestUnapproveSelectedPublicDeck,
} from "@liscioapps/quizme-client-library";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import {
  Container,
  Paper,
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useQuizSnackbar } from "../../hooks/useQuizSnackbar";
import { QuizSnackbar } from "../../components/QuizSnackbar";
import PublicCardPreviewDialogue from "../../components/PublicCardPreviewDialogue";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function DeckDetails() {
  const history = useHistory();
  let { publicDeckId } = useParams<{ publicDeckId: string }>();
  const deck = useAppSelector((s) => s.selectedPublicDeck.selectedPublicDeck);
  const userIsAdmin = useAppSelector((s) => s.auth.isAdmin);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSelectedPublicDeck(Number(publicDeckId)));
  }, []);

  const {
    quizSnackbarProps,
    setQuizSnackbarProps,
    showErrorMessage,
    showSuccessMessage,
  } = useQuizSnackbar();

  const [selectedCard, setSelectedCard] = useState<PublicCardModel | null>(
    null
  );

  const QuizCard = (props: { card: PublicCardModel }) => (
    <Card
      onClick={() => setSelectedCard(props.card)}
      style={{ cursor: "pointer" }}
    >
      <CardContent>
        <Typography variant="h5" noWrap={true} textOverflow="ellipsis">
          {props.card.questionContent}
        </Typography>
      </CardContent>
    </Card>
  );

  const onAddToMyDecks = async () => {
    dispatch(addPublicDeckToMyDecks(Number(publicDeckId)));
  };

  const approveDeck = async () => {
    dispatch(requestApproveSelectedPublicDeck());
  };

  const unapproveDeck = async () => {
    dispatch(requestUnapproveSelectedPublicDeck());
  };

  const privateDeckId = useAppSelector(
    (s) => s.selectedPublicDeck.privateDeckId
  );

  const errorMessage = useAppSelector((s) => s.selectedPublicDeck.errorMessage);
  const hasError = useAppSelector((s) => s.selectedPublicDeck.hasError);

  useEffect(() => {
    if (privateDeckId) {
      showSuccessMessage(`${deck.title} added to your decks.`);
    }
  }, [privateDeckId]);

  useEffect(() => {
    if (hasError) {
      showErrorMessage(errorMessage || "Public deck error. Please try again.");
    }
  }, [hasError, errorMessage]);

  const ActionsMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={approveDeck} disabled={deck.approved}>
            Approve
          </MenuItem>
          <MenuItem onClick={unapproveDeck} disabled={!deck.approved}>
            Un-approve
          </MenuItem>
        </Menu>
      </div>
    );
  };

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3" sx={{ flex: 1 }}>
            {deck?.title}
          </Typography>
          {userIsAdmin && <ActionsMenu />}
        </Box>
        <Typography variant="h5">{deck?.description}</Typography>

        {userIsAdmin && deck.approved && (
          <Typography variant="subtitle2" color={"green"}>
            Approved
          </Typography>
        )}
        {userIsAdmin && !deck.approved && (
          <Typography variant="subtitle2" color={"red"}>
            Not Appproved
          </Typography>
        )}

        <Box sx={{ mt: 2, mb: 4 }}>
          <Button variant="contained" fullWidth onClick={onAddToMyDecks}>
            Add to my decks
          </Button>
        </Box>
        <Paper sx={{ mt: 2, p: 4 }}>
          <Grid container spacing={3}>
            {deck.cards?.map((card) => (
              <Grid item xs={12} md={6} key={card.id}>
                <QuizCard card={card} />
              </Grid>
            ))}
          </Grid>
        </Paper>
        {!!selectedCard && (
          <PublicCardPreviewDialogue
            show={!!selectedCard}
            card={selectedCard!}
            handleClose={() => setSelectedCard(null)}
          ></PublicCardPreviewDialogue>
        )}
      </Container>
      <QuizSnackbar
        state={quizSnackbarProps}
        setState={setQuizSnackbarProps}
      ></QuizSnackbar>
    </>
  );
}
