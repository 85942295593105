import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { PublicCardModel } from "@liscioapps/quizme-client-library";
import { TextField } from "@mui/material";

export default function PublicCardPreviewDialogue(props: {
  show: boolean;
  card: PublicCardModel;
  handleClose: () => void;
}) {
  const { show, card, handleClose } = props;

  return (
    <Dialog open={show} onClose={handleClose} fullWidth={true} maxWidth="md">
      <DialogTitle id="scroll-dialog-title">Preview Card</DialogTitle>
      <DialogContent dividers={true}>
        <TextField
          rows={5}
          tabIndex={0}
          autoFocus
          margin="dense"
          value={card.questionContent}
          label="Question"
          fullWidth
          variant="outlined"
          multiline={true}
        />
        <TextField
          rows={5}
          tabIndex={1}
          margin="dense"
          value={card.questionAnswer}
          label="Answer"
          fullWidth
          variant="outlined"
          multiline={true}
        />
      </DialogContent>
    </Dialog>
  );
}
