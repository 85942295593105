// needed for emotion styling to work
/** @jsxImportSource @emotion/react */

import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, {
  ChangeEventHandler,
  DragEventHandler,
  LegacyRef,
  useEffect,
  useState,
} from "react";
import { useRef } from "react";
import { css } from "@emotion/react";
import {
  DefaultErrorResult,
  useCardService,
} from "@liscioapps/quizme-client-library";
import { useAppDispatch } from "../../hooks/useStore";
import Link from "@mui/material/Link";

interface UploadProps {
  onClose: () => void;
  onImportSuccess: () => void;
  show: boolean;
  deckId: number;
}

export const FileUpload: React.FC<UploadProps> = ({
  onClose,
  onImportSuccess,
  show,
  deckId,
}) => {
  const dispatcher = useAppDispatch();
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef: LegacyRef<HTMLInputElement> = useRef(null);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [uploadWaitDone, setUploadWaitDone] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [uploadError, setUploadError] = useState<DefaultErrorResult>();
  const [uploadResultCount, setUploadResultCount] = useState<number>();
  const [uploadStatus, setUploadStatus] = useState<
    "initial" | "uploading" | "error" | "success"
  >("initial");

  const { importCardsCsv, getExampleCsv } = useCardService(dispatcher);

  async function handleFile(file: File) {
    setFileToUpload(file);
    setUploadStatus("uploading");
    setUploadWaitDone(false);
    setTimeout(() => {
      setUploadWaitDone(true);
    }, 2000);
    try {
      const response = await importCardsCsv(deckId, file);
      setUploadResultCount(response.importedCardsCount);
      setUploadStatus("success");
      onImportSuccess();
    } catch (e) {
      console.log(e);
      setUploadError(e.data);
      setUploadStatus("error");
    }
  }

  useEffect(() => {
    if (uploadStatus === "uploading") {
      setShowLoading(true);
    } else if (!uploadWaitDone) {
      setShowLoading(true);
    } else {
      setShowLoading(false);
    }
  }, [uploadStatus, uploadWaitDone]);

  // handle drag events
  const handleDrag: DragEventHandler<HTMLDivElement | HTMLFormElement> = (
    e
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const userDidClickSelectFile = () => {
    inputRef.current?.click();
  };

  const downloadSampleRef = useRef<HTMLAnchorElement | null>(null);
  const [downloadSampleUrl, setDownloadSampleUrl] = useState<string>();
  const [downloadSampleFileName, setDownloadSampleFileName] =
    useState<string>();

  const userDidClickDownloadExample = async () => {
    const { data, filename } = await getExampleCsv();
    const url = URL.createObjectURL(new Blob([data]));
    setDownloadSampleUrl(url);
    setDownloadSampleFileName(filename);
    downloadSampleRef.current?.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Dialog fullWidth maxWidth={"md"} open={show} onClose={onClose}>
      <DialogTitle>Import Cards from File</DialogTitle>
      <DialogContent>
        {/*<DialogContentText>Upload Csv</DialogContentText>*/}
        <Grid
          container
          css={css`
            align-items: center;
            justify-content: center;
          `}
        >
          <form
            css={css`
              height: 16rem;
              width: 28rem;
              max-width: 100%;
              text-align: center;
              position: relative;
            `}
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              css={css`
                display: none;
              `}
              ref={inputRef}
              type="file"
              accept="text/csv"
              id="input-file-upload"
              multiple={false}
              onChange={handleChange}
            />
            <div
              css={css`
                height: 100%;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-width: 2px;
                border-radius: 1rem;
                border-style: dashed;
                border-color: #cbd5e1;
                background-color: ${dragActive ? "#ffffff" : "#f8fafc"};
              `}
            >
              {uploadStatus === "initial" && (
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <Typography>
                    Drag and drop .csv file here or{" "}
                    <Link
                      component="button"
                      variant="body1"
                      onClick={userDidClickSelectFile}
                    >
                      select a file.
                    </Link>
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={userDidClickDownloadExample}
                    css={css`
                      margin-top: 25px;
                    `}
                  >
                    Download Example Template
                  </Button>
                </div>
              )}
              {showLoading && (
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <Typography variant={"body1"}>
                    Importing {fileToUpload?.name}
                  </Typography>
                  <CircularProgress
                    css={css`
                      margin-top: 10px;
                    `}
                  />
                </div>
              )}
              {!showLoading && uploadStatus === "error" && (
                <>
                  <Alert severity="error">
                    <AlertTitle>Error uploading</AlertTitle>
                    {uploadError?.errorMessage}
                  </Alert>
                  <Typography variant={"body1"}></Typography>
                </>
              )}
              {!showLoading && uploadStatus === "success" && (
                <>
                  <Alert severity="success">
                    <AlertTitle>{uploadResultCount} cards imported!</AlertTitle>
                    Close this dialog to review.
                  </Alert>
                  <Typography variant={"body1"}></Typography>
                </>
              )}
            </div>
            {dragActive && (
              <div
                id="drag-file-element"
                css={css`
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  border-radius: 1rem;
                  top: 0px;
                  right: 0px;
                  bottom: 0px;
                  left: 0px;
                `}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </form>
          <a
            href={downloadSampleUrl}
            download={downloadSampleFileName}
            css={css`
              display: none;
            `}
            ref={downloadSampleRef}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
