import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppLayout from "./layout/AppLayout";
import { RequireAuth } from "./RequreAuth";
import DeckDetails from "./screens/DeckDetails/Index";
import PublicDeckDetails from "./screens/PublicDeckDetails/Index";
import PublicDecksList from "./screens/PublicDecksList/Index";
import SignIn from "./screens/SignIn/Index";
import SignUp from "./screens/SignUp/Index";
import { useDispatch } from "react-redux";
import {
  quizMeConfig,
  AuthService,
  HttpClientService,
  setTokenExpired,
} from "@liscioapps/quizme-client-library";
import { useAuth } from "./hooks/useAuth";
import SettingsScreen from "./screens/SettingsScreen/Index";
import DashboardScreen from "./screens/Dashboard/Index";
import DecksList from "./screens/DecksList/Index";
import WithAppbarTitle from "./components/hoc/withAppbarTitle";
import ReviewCards from "./screens/ReviewCards/Index";
import ForgotPassword from "./screens/ForgotPassword/Index";
import ResetPassword from "./screens/ResetPassword/Index";
import LandingPage from "./screens/LandingPage/Index";
import Terms from "./screens/Terms/Index";
import Privacy from "./screens/Privacy/Index";
import { DateTime } from "luxon";
import { useAppSelector } from "./hooks/useStore";
import { Typography } from "@mui/material";

function App() {
  const dispatch = useDispatch();
  const { refreshAuthToken, logout, loadUserInformation } = useAuth();
  const tokenExpired = useAppSelector((state) => state.auth.tokenExpired);
  const tokenExpiresAt: any = useAppSelector(
    (state) => state.auth.tokenExpiresAt
  );

  // define when token expired, we run token refresh again
  useEffect(() => {
    // initially token expired is set to null, meaning we need to refresh the token anyway
    console.log(tokenExpired);
    if (null === tokenExpired) {
      refreshTokenFunc();
    } else if (tokenExpired === true) {
      // the token is expired, refresh token here.
      refreshTokenFunc();
    } else {
      // the token not expired, so check again later
      setTimeout(
        () => checkTokenExpiration(),
        1000 * (quizMeConfig.checkAndRefreshTokenPeriodically || 30)
      );
    }
  }, [tokenExpired]);

  const refreshTokenFunc = async () => {
    const hasTokenForRefreshAuthToken =
      await AuthService.Instance.hasRefreshToken();

    if (hasTokenForRefreshAuthToken) {
      try {
        await refreshAuthToken(DateTime.now().zoneName);
        await loadUserInformation();
      } catch (error) {
        // refresh token expired, ask user to re-login
        if (error.status === 401) {
          await logout(true);
        }
      }
    } else {
      dispatch(setTokenExpired(true));
    }
  };

  function checkTokenExpiration() {
    const now = new Date();
    const tokenExpiresAtDateTime = new Date(tokenExpiresAt);

    console.log(
      "checking token expiration...",
      tokenExpiresAt,
      " < ",
      now,
      tokenExpiresAtDateTime < now
    );

    if (tokenExpiresAtDateTime < now) {
      HttpClientService.Instance.setDefaultHeader("authorization", null);
      console.log("token expired, refresh new token...");
      dispatch(setTokenExpired(true));
      return;
    }

    dispatch(setTokenExpired(new Date().getTime()));
  }

  return null === tokenExpired ? (
    <Typography>Loading...</Typography>
  ) : (
    <Router>
      <Switch>
        <Route path="/" exact={true}>
          <LandingPage></LandingPage>
        </Route>
        <Route path="/terms" exact={true}>
          <Terms></Terms>
        </Route>
        <Route path="/privacy" exact={true}>
          <Privacy></Privacy>
        </Route>
        <Route path="/login" exact={true}>
          <SignIn></SignIn>
        </Route>
        <Route path="/signup" exact={true}>
          <SignUp></SignUp>
        </Route>
        <Route path="/forgot-password" exact={true}>
          <ForgotPassword></ForgotPassword>
        </Route>
        <Route path="/reset-password/:passwordResetToken" exact={true}>
          <ResetPassword></ResetPassword>
        </Route>
        <RequireAuth>
          <Route path="/app">
            <AppLayout>
              <Switch>
                <Route path={`/app`} exact={true}>
                  <WithAppbarTitle title="Home">
                    <DashboardScreen />
                  </WithAppbarTitle>
                </Route>
                <Route path={`/app/decks`} exact={true}>
                  <WithAppbarTitle title="Decks">
                    <DecksList />
                  </WithAppbarTitle>
                </Route>
                <Route path={`/app/decks/:deckId`} exact={true}>
                  <WithAppbarTitle title="Deck Details">
                    <DeckDetails />
                  </WithAppbarTitle>
                </Route>
                <Route path={`/app/publicDecks`} exact={true}>
                  <WithAppbarTitle title="Public Decks">
                    <PublicDecksList />
                  </WithAppbarTitle>
                </Route>
                <Route path={`/app/publicDecks/:publicDeckId`} exact={true}>
                  <WithAppbarTitle title="Deck Details">
                    <PublicDeckDetails />
                  </WithAppbarTitle>
                </Route>
                <Route path={`/app/review`} exact={true}>
                  <WithAppbarTitle title="Review">
                    <ReviewCards />
                  </WithAppbarTitle>
                </Route>
                <Route path={`/app/settings`} exact={true}>
                  <WithAppbarTitle title="Settings">
                    <SettingsScreen />
                  </WithAppbarTitle>
                </Route>
              </Switch>
            </AppLayout>
          </Route>
        </RequireAuth>
      </Switch>
    </Router>
  );
}

export default App;
