import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";

export const ConfirmDeleteAccountDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  onDeleteConfirm: () => Promise<void>;
}> = ({ open, onClose, onDeleteConfirm }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setIsDeleting(false);
  }, [open]);
  const internalDeleteConfirm = async () => {
    setIsDeleting(true);
    try {
      await onDeleteConfirm();
    } catch {
      alert("There was an issue deleting your account. Contact support.");
      setIsDeleting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogTitle>Are you sure you want to delete your account?</DialogTitle>
        <DialogContentText id="alert-dialog-description">
          Deleting your account will delete all of your data. This action is not
          reversible.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus disabled={isDeleting}>
          Cancel
        </Button>

        <Button
          disabled={isDeleting}
          onClick={internalDeleteConfirm}
          color={"error"}
          variant={"contained"}
        >
          Delete all my data
          {isDeleting && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
