import {
  moveToNextCard,
  setCardsToReview,
  useCardService,
} from "@liscioapps/quizme-client-library";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";

const ReviewCards = () => {
  const dispatcher = useAppDispatch();
  const params = useParams<any>();
  const history = useHistory();
  const [cardLoading, setCardLoading] = useState(false);
  const {
    getList: getCardList,
    reviewCard,
    getDueCardsByDeck,
    getAllDueCardsByUser,
  } = useCardService(dispatcher);

  const onCardAnswered = async (score: 1 | 2 | 3 | 4 | 5) => {
    await reviewCard(currentCard?.id!, score);
    setCardSide("front");
    dispatcher(moveToNextCard());
  };

  const goBackToDecksList = () => {
    history.push("/app/decks");
  };

  const currentCard = useAppSelector((s) => s.review.currentCard);
  const [cardSide, setCardSide] = useState<"front" | "back">("front");

  const location = useLocation<any>();
  const query = new URLSearchParams(window.location.search);

  useEffect(() => {
    setCardLoading(true);
    if (query.get("deckId") && query.get("learnMode") === "due") {
      getDueCardsByDeck(Number(query.get("deckId"))).then((_) => {
        dispatcher(setCardsToReview(_));
      });
    } else if (query.get("deckId")) {
      getCardList(Number(query.get("deckId"))).then((_) => {
        dispatcher(setCardsToReview(_));
      });
    } else {
      getAllDueCardsByUser().then((_) => {
        dispatcher(setCardsToReview(_));
      });
    }
    setCardLoading(false);
  }, []);
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Card sx={{ height: 500, display: "flex", flexDirection: "column" }}>
        {currentCard !== null ? (
          <React.Fragment>
            <CardContent sx={{ flex: 1, p: 4 }}>
              <Typography variant="body1">
                {cardSide === "front"
                  ? currentCard?.questionContent
                  : currentCard?.questionAnswer}
              </Typography>
            </CardContent>

            {cardSide === "front" ? (
              <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button size="large" onClick={() => setCardSide("back")}>
                  Show Answer
                </Button>
              </CardActions>
            ) : (
              <CardActions sx={{ display: "flex" }}>
                <Box sx={{ display: "flex" }}>
                  <Button onClick={() => setCardSide("front")}>
                    <ArrowBackIosIcon />
                  </Button>
                </Box>
                <Box
                  sx={{ display: "flex", justifyContent: "end", flexGrow: 2 }}
                >
                  <Typography variant="body1">
                    How well did you know that?
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
                >
                  <Button
                    size="large"
                    sx={{ fontSize: 24 }}
                    onClick={() => onCardAnswered(1)}
                  >
                    😔
                  </Button>
                  {/* <Button size="large" onClick={() => onCardAnswered(2)}>
                    2
                  </Button> */}
                  <Button
                    size="large"
                    sx={{ fontSize: 24 }}
                    onClick={() => onCardAnswered(3)}
                  >
                    😐
                  </Button>
                  {/* <Button size="large" onClick={() => onCardAnswered(4)}>
                    4
                  </Button> */}
                  <Button
                    size="large"
                    sx={{ fontSize: 24 }}
                    onClick={() => onCardAnswered(5)}
                  >
                    😀
                  </Button>
                </Box>
              </CardActions>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!cardLoading && (
              <CardContent
                sx={{
                  p: 4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4" color="text.secondary">
                    {query.get("demo")
                      ? "You just reviewed your first deck! 🎉"
                      : "You are all caught up!"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Button size="large" onClick={goBackToDecksList}>
                    Back to Decks
                  </Button>
                </Box>
              </CardContent>
            )}
          </React.Fragment>
        )}
      </Card>
    </Container>
  );
};

export default ReviewCards;
