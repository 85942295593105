import {
  useDateTimeFormatter,
  useDebounce,
  publicSearchTermChanged,
  fetchNextPublicDeckListPage,
  fetchInitialPublicDeckList,
  PublicDeckSummaryModel,
} from "@liscioapps/quizme-client-library";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { useHistory } from "react-router-dom";
import { useUrlQuery } from "../../hooks/useUrlQuery";

function PublicDecksList() {
  const history = useHistory();
  const userIsAdmin = useAppSelector((s) => s.auth.isAdmin);

  const dispatch = useAppDispatch();
  const drawerOpen = useAppSelector((s) => s.settings.drawerOpen);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const publicDecksState = useAppSelector((state) => state.publicDecks);
  const orderBy = useAppSelector(
    (s) => s.publicDecks.currentPaginationSettings.orderBy
  );

  // const handleOrderByChange = (event: any) => {
  //   // hardcoding as desc now b/c no UI to change it :grimace:
  //   dispatch(sortPropertiesChanged(event.target.value, "desc"));
  // };

  // const [showCreateDeckDialogue, setShowCreateDeckDialogue] = useState(
  //   query.get("showCreate") === "true"
  // );

  // const { createDeck } = useDeckService();

  // const handleCreate = async (title: string, description: string) => {
  //   const response = await createDeck({ __id: nanoid(), title, description });
  //   history.push(`/app/decks/${response.entityId}`);
  // };

  const { toReadableDateTime } = useDateTimeFormatter();

  useEffect(() => {
    // chill out, you don't need to fire this off if the list hasn't loaded yet
    if (initiallyLoaded) {
      dispatch(publicSearchTermChanged(debouncedSearchTerm));
    }
  }, [debouncedSearchTerm, dispatch]);

  const loadMore = () => {
    dispatch(fetchNextPublicDeckListPage());
  };

  const hasMoreDecks = useAppSelector((s) => s.publicDecks.hasMoreDecks);
  const initiallyLoaded = useAppSelector((s) => s.publicDecks.hasInitialLoad);

  useEffect(() => {
    // fires if we refresh (e.g. do a new initial load) or if decklist changes
    // but not until the initial load happens
    if (initiallyLoaded) {
      setupInfiniteScrollObserver();
    }
  }, [publicDecksState.publicDecksList, initiallyLoaded]);

  const loader = useRef<any>(null);

  const handleObserver: IntersectionObserverCallback = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && hasMoreDecks) {
      loadMore();
    }
  };

  const observer = useRef<IntersectionObserver>();

  const setupInfiniteScrollObserver = () => {
    // clear out any existing observer
    observer.current?.disconnect();
    // initialize IntersectionObserver and attaching to Load More div
    observer.current = new IntersectionObserver(handleObserver);
    console.log("running observer");
    if (loader.current) {
      console.log("observing for real");
      observer.current?.observe(loader.current);
    }
  };

  useEffect(() => {
    dispatch(fetchInitialPublicDeckList());
  }, []);

  async function navigateToDetail(publicDeckId: number) {
    history.push(`/app/publicDecks/${publicDeckId}`);
  }

  const Deck = (props: { deck: PublicDeckSummaryModel }) => (
    <Card
      sx={{ cursor: "pointer", height: "100%" }}
      onClick={() => {
        navigateToDetail(props.deck.id!);
      }}
    >
      <CardHeader
        title={props.deck.title}
        subheader={
          userIsAdmin ? (
            <Typography
              variant="subtitle2"
              color={props.deck.approved ? "green" : "red"}
            >
              {props.deck.approved ? "Approved" : "No approved"}
            </Typography>
          ) : null
        }
      />
      <CardContent>
        <Typography
          variant="subtitle1"
          component="div"
          noWrap={true}
          textOverflow="ellipsis"
        >
          {props.deck.description}
        </Typography>
      </CardContent>
    </Card>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ mt: 2, p: 4 }}>
        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              variant="outlined"
              label="Search"
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
        {publicDecksState.hasInitialLoad &&
          !publicDecksState.publicDecksList.length && (
            <Grid container>
              <Grid item xs={12} sx={{ paddingTop: 1 }}>
                <Typography textAlign={"center"}>
                  {debouncedSearchTerm ? "No decks found." : "No decks yet."}
                </Typography>
              </Grid>
            </Grid>
          )}
        <Grid container spacing={3}>
          {publicDecksState.publicDecksList.map((deck) => (
            <Grid item xs={12} md={6} key={deck.id}>
              <Deck deck={deck} />
            </Grid>
          ))}
          <Grid item xs={12} ref={loader}>
            {hasMoreDecks && <div>Loading...</div>}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default PublicDecksList;
