import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CardModel } from "@liscioapps/quizme-client-library";
import { useState } from "react";
import { FormGroup, TextField } from "@mui/material";
import { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function CardEdit(props: {
  show: boolean;
  card: CardModel;
  handleClose: () => void;
  handleSaveCard: (
    question: string,
    answer: string,
    createAnother: boolean
  ) => void;
  handleDeleteCard: (cardId: number) => void;
}) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    setQuestion(props.card.questionContent ?? "");
    setAnswer(props.card.questionAnswer ?? "");
    setShowConfirmDelete(false);
  }, [props.card]);

  const onCancel = () => {
    props.handleClose();
  };

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [createAnother, setCreateAnother] = useState(false);

  return (
    <Dialog
      open={props.show}
      onClose={props.handleClose}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="scroll-dialog-title">
        {!props.card.id ? "Add Card" : "Edit Card"}
      </DialogTitle>
      <DialogContent dividers={true}>
        <TextField
          rows={5}
          tabIndex={0}
          autoFocus
          margin="dense"
          value={question}
          onChange={(event) => {
            setQuestion(event.target.value);
          }}
          label="Question"
          fullWidth
          variant="outlined"
          multiline={true}
        />
        <TextField
          rows={5}
          tabIndex={1}
          margin="dense"
          value={answer}
          onChange={(event) => {
            setAnswer(event.target.value);
          }}
          label="Answer"
          fullWidth
          variant="outlined"
          multiline={true}
        />
      </DialogContent>
      <DialogActions>
        {props.card.id && (
          <React.Fragment>
            {!showConfirmDelete ? (
              <Button
                startIcon={<DeleteIcon />}
                onClick={() => setShowConfirmDelete(true)}
              >
                Delete
              </Button>
            ) : (
              <Button
                startIcon={<DeleteIcon />}
                color="error"
                onClick={() => props.handleDeleteCard(props.card.id!)}
              >
                Confirm
              </Button>
            )}
          </React.Fragment>
        )}
        {!props.card.id && (
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={createAnother} />}
              label="Create Another"
              onChange={(event, checked) => setCreateAnother(checked)}
            />
          </FormGroup>
        )}
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant="outlined"
          onClick={() => props.handleSaveCard(question, answer, createAnother)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
