import { datadogLogs } from "@datadog/browser-logs";
import packageJson from "../package.json";

const clientToken = "pub3b8457f9cb1c8fad9c4b655180361d49";

export async function initDatadog() {
  datadogLogs.init({
    clientToken,
    service: "quizme.webclient",
    env: process.env.NODE_ENV,
    version: packageJson.version,
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });
  datadogLogs.logger.info("App init");
}
