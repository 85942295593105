import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../hooks/useAuth";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthenticationResultModel } from "@liscioapps/quizme-client-library";
import { Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import logo from "../../assets/quizme-logo-2022.png";
import { useAppSelector } from "../../hooks/useStore";
import { useKeyPress } from "../../hooks/useKeypress";

export default function ForgotPassword(props: any) {
  const { push } = useHistory();
  const userLoggedIn = useAppSelector((s) => s.auth.userLoggedIn);

  const { login, loadUserInformation, requestForgotPassword } = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    if (userLoggedIn) {
      push("/app");
    }
    return () => {};
  }, [userLoggedIn]);

  const submitRequestForgotPassword = async () => {
    try {
      const response: AuthenticationResultModel = await requestForgotPassword(
        username
      );
      alert("Please follow instruction to reset your password");
      push("/app");
    } catch (error) {
      console.error(error);
    }
  };

  useKeyPress(["Enter", "NumpadEnter"], submitRequestForgotPassword);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="Logo" />
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <Typography variant="h4">Reset Password</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={username}
            helperText="Email required"
            onChange={(_) => setUsername(_.target.value)}
          />
          <Button
            color="primary"
            onClick={submitRequestForgotPassword}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Send Instructions
          </Button>
          <Grid container>
            <Grid item xs></Grid>
            <Grid item>
              <Link component={RouterLink} to="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
