import OnboardingCards from "../../assets/onboarding/onboarding_cards.png";
import OnboardingSingleCard from "../../assets/onboarding/onboarding_single_card.png";
import OnboardingArrows from "../../assets/onboarding/onboarding_arrows.png";
import OnboardingAtom from "../../assets/onboarding/onboarding_atom.png";
import { Button, Typography } from "@mui/material";

export interface WelcomeScreenProps {
  icon: () => JSX.Element;
  title: string;
  description: string;
  description2?: string;
  footer?: () => JSX.Element;
}

export const welcomeScreenConfig: WelcomeScreenProps[] = [
  {
    icon: () => (
      <img
        style={{ objectFit: "contain", width: "100%", height: 120 }}
        src={OnboardingCards}
        alt="Logo"
      />
    ),
    title: "Flashcards that actually work",
    description: "If you can use flashcards, you can use QuizMe",
    description2: `The difference is: QuizMe is backed by science...and doesn't use any trees.`,
  },
  {
    icon: () => (
      <img
        style={{ objectFit: "contain", width: "100%", height: 120 }}
        src={OnboardingSingleCard}
        alt="Logo"
      />
    ),
    title: "The power of Spaced Repetition",
    description: "Evidence-based learning using science!",
    description2: `The spaced repetition algorithm has been proven to help you better retain concepts and increase learning efficiency.`,
  },
  {
    icon: () => (
      <img
        style={{ objectFit: "contain", width: "100%", height: 120 }}
        src={OnboardingAtom}
        alt="Logo"
      />
    ),
    title: "Simple to use",
    description: `Just tell QuizMe how confident you are in answering each card with a simple emoji. We'll do the rest!`,
    footer: () => <ScoreSelectRow />,
  },
  {
    icon: () => (
      <img
        style={{ objectFit: "contain", width: "100%", height: 120 }}
        src={OnboardingArrows}
        alt="Logo"
      />
    ),
    title: `Get Started for Free`,
    description:
      "Access dozens of pre-built decks of cards, or build the deck you want to work on.",
    description2:
      "Free to start, no credit card required. If you love it, then you can subscribe to support QuizMe.",
  },
];

const ScoreSelectRow = () => (
  <div
    style={{
      display: "flex",
      width: "100%",
      justifyContent: "center",
    }}
  >
    <Button
      style={{ marginLeft: 5, marginRight: 5 }}
      variant={"outlined"}
      onClick={() => {}}
    >
      <Typography style={{ fontSize: 30 }}>😔</Typography>
    </Button>

    <Button
      style={{ marginLeft: 5, marginRight: 5 }}
      variant={"outlined"}
      onClick={() => {}}
    >
      <Typography style={{ fontSize: 30 }}>😐</Typography>
    </Button>

    <Button
      style={{ marginLeft: 5, marginRight: 5 }}
      variant={"outlined"}
      onClick={() => {}}
    >
      <Typography style={{ fontSize: 30 }}>😀</Typography>
    </Button>
  </div>
);
