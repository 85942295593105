import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useAuth } from "../../hooks/useAuth";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthenticationResultModel } from "@liscioapps/quizme-client-library";
import { Alert, Box, Paper, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import logo from "../../assets/quizme-logo-2022.png";
import AppHeaderBar from "../../components/AppBar";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { setTheme } from "../../reducers/appSettingsReducer";
import { useKeyPress } from "../../hooks/useKeypress";

export default function SignIn(props: any) {
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const userLoggedIn = useAppSelector((_) => _.auth.userLoggedIn);

  const { login, loadUserInformation } = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    if (userLoggedIn) {
      push("/app");
    } else {
      // we only support the light theme on the sign in page
      dispatch(setTheme("light"));
    }
  }, [userLoggedIn]);

  const handleLogin = async () => {
    try {
      const response: AuthenticationResultModel = await login(
        username,
        password
      );
      try {
        const userProfileResponse = await loadUserInformation();
        console.log(userProfileResponse);
      } catch (error) {}
      push("/app");
    } catch (error) {
      console.error(error);
      // TODO: handle localizatio and error keys
      setLoginError("Invalid username or password.");
    }
  };

  useKeyPress(["Enter", "NumpadEnter"], handleLogin);

  return (
    <div className="welcome-area">
      <React.Fragment>
        <AppHeaderBar />
        <Container component="main" maxWidth="xs" className="login-area">
          <CssBaseline />
          <Paper
            elevation={4}
            sx={{
              padding: 2,
              marginTop: 8,
            }}
          >
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={logo} alt="Logo" width={150} />
              <Typography variant="h4">Login</Typography>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={username}
                  helperText="Email required"
                  onChange={(_) => setUsername(_.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(_) => setPassword(_.target.value)}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                {loginError && (
                  <Grid item xs={12}>
                    <Alert severity="error">{loginError}</Alert>
                  </Grid>
                )}
                <Button
                  color="primary"
                  onClick={handleLogin}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link
                      component={RouterLink}
                      to="/forgot-password"
                      variant="body2"
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link component={RouterLink} to="/signup" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Container>
      </React.Fragment>
    </div>
  );
}
