import {
  DeckSummaryModel,
  useDateTimeFormatter,
  fetchInitialDeckList,
  fetchNextDeckListPage,
  sortPropertiesChanged,
  searchTermChanged,
  useDeckService,
  useDebounce,
} from "@liscioapps/quizme-client-library";
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import CreateDeckDialogue from "../../components/CreateDeckComponent";
import { nanoid } from "@reduxjs/toolkit";
import { useUrlQuery } from "../../hooks/useUrlQuery";
import { SubscsribePromptDialog } from "../../components/SubscribePromptDialog";
import { useKeyPress } from "../../hooks/useKeypress";
import { canAddDecksSelector } from "../../selectors/canAddItemsSelector";

function DecksList() {
  const history = useHistory();
  let query = useUrlQuery();

  const dispatch = useAppDispatch();
  const drawerOpen = useAppSelector((s) => s.settings.drawerOpen);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const deckState = useAppSelector((state) => state.decks);
  const orderBy = useAppSelector(
    (s) => s.decks.currentPaginationSettings.orderBy
  );

  const handleOrderByChange = (event: any) => {
    // hardcoding as desc now b/c no UI to change it :grimace:
    dispatch(sortPropertiesChanged(event.target.value, "desc"));
  };

  const [showCreateDeckDialogue, setShowCreateDeckDialogue] = useState(
    query.get("showCreate") === "true"
  );

  const [showSubscribeDialog, setShowSubscribeDialog] = useState(false);

  const { createDeck } = useDeckService();

  const handleCreate = async (title: string, description: string) => {
    const response = await createDeck({ __id: nanoid(), title, description });
    history.push(`/app/decks/${response.entityId}`);
  };

  const { toReadableDateTime } = useDateTimeFormatter();

  useEffect(() => {
    // chill out, you don't need to fire this off if the list hasn't loaded yet
    if (initiallyLoaded) {
      dispatch(searchTermChanged(debouncedSearchTerm));
    }
  }, [debouncedSearchTerm, dispatch]);

  const loadMore = () => {
    dispatch(fetchNextDeckListPage());
  };

  const hasMoreDecks = useAppSelector((s) => s.decks.hasMoreDecks);
  const initiallyLoaded = useAppSelector((s) => s.decks.hasInitialLoad);

  useEffect(() => {
    // fires if we refresh (e.g. do a new initial load) or if decklist changes
    // but not until the initial load happens
    if (initiallyLoaded) {
      setupInfiniteScrollObserver();
    }
  }, [deckState.decksList, initiallyLoaded]);

  const loader = useRef<any>(null);

  const handleObserver: IntersectionObserverCallback = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && hasMoreDecks) {
      loadMore();
    }
  };

  const observer = useRef<IntersectionObserver>();

  const setupInfiniteScrollObserver = () => {
    // clear out any existing observer
    observer.current?.disconnect();
    // initialize IntersectionObserver and attaching to Load More div
    observer.current = new IntersectionObserver(handleObserver);
    console.log("running observer");
    if (loader.current) {
      console.log("observing for real");
      observer.current?.observe(loader.current);
    }
  };

  useEffect(() => {
    dispatch(fetchInitialDeckList());
  }, []);

  const userDidClickAddDeck = () => {
    if (canAddDeck) {
      setShowCreateDeckDialogue(true);
    } else {
      setShowSubscribeDialog(true);
    }
  };

  useKeyPress(["KeyA"], () => userDidClickAddDeck(), !showCreateDeckDialogue);

  async function navigateToDetail(deckId: number) {
    history.push(`/app/decks/${deckId}`);
  }

  const canAddDeck = useAppSelector(canAddDecksSelector);

  // const canAddDeck = useAppSelector(
  //   (s) =>
  //     s.auth.subscriptionSettings.numberOfDecksAllowed === null ||
  //     s.auth.subscriptionSettings.numberOfDecksAllowed >
  //       s.decks.decksList.length
  // );

  const Deck = (props: { deck: DeckSummaryModel }) => (
    <Card
      sx={{ cursor: "pointer", height: "100%" }}
      onClick={() => {
        navigateToDetail(props.deck.id!);
      }}
    >
      <CardHeader
        action={
          props.deck.dueCardCount ? (
            <Chip label={`${props.deck.dueCardCount} due`} />
          ) : (
            ""
          )
        }
        title={props.deck.title}
        subheader={
          props.deck.modifiedDate
            ? "Updated: " +
              toReadableDateTime(new Date(props.deck.modifiedDate!))
            : "Created: " +
              toReadableDateTime(new Date(props.deck.createdDate!))
        }
      />
      <CardContent>
        <Typography
          variant="subtitle1"
          component="div"
          noWrap={true}
          textOverflow="ellipsis"
        >
          {props.deck.description}
        </Typography>
      </CardContent>
    </Card>
  );

  const CustomFab = styled(Fab)(({ theme }) => ({
    position: "fixed",
    [theme.breakpoints.up("xs")]: {
      bottom: theme.spacing(5),
      right: theme.spacing(6),
    },
    [theme.breakpoints.up("md")]: {
      bottom: theme.spacing(8),
      right: theme.spacing(8),
    },
    [theme.breakpoints.up("lg")]: {
      bottom: theme.spacing(8),
      right: drawerOpen
        ? "calc((100vw - 1100px)/2 )"
        : "calc((100vw - 940px) /2 )",
    },
  }));

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ mt: 2, p: 4 }}>
        <Grid container spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              variant="outlined"
              label="Search"
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Order By</InputLabel>
              <Select
                value={orderBy}
                label="Order By"
                onChange={handleOrderByChange}
              >
                <MenuItem value="DueCardCount">Due</MenuItem>
                <MenuItem value="ModifiedDate">Modified</MenuItem>
                <MenuItem value="CreatedDate">Created</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {deckState.hasInitialLoad && !deckState.decksList.length && (
          <Grid container>
            <Grid item xs={12} sx={{ paddingTop: 1 }}>
              <Typography textAlign={"center"}>
                {debouncedSearchTerm
                  ? "No decks found."
                  : "No decks yet. Click add button to add a deck!"}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={3}>
          {deckState.decksList.map((deck) => (
            <Grid item xs={12} md={6} key={deck.id}>
              <Deck deck={deck} />
            </Grid>
          ))}
          <Grid item xs={12} ref={loader}>
            {hasMoreDecks && <div>Loading...</div>}
          </Grid>
        </Grid>
      </Paper>
      <CustomFab color="secondary" onClick={userDidClickAddDeck}>
        <AddIcon />
      </CustomFab>
      <SubscsribePromptDialog
        onClose={() => setShowSubscribeDialog(false)}
        onSubscribeClicked={() => history.push(`/app/settings`)}
        open={showSubscribeDialog}
        type={"decks"}
      ></SubscsribePromptDialog>
      <CreateDeckDialogue
        open={showCreateDeckDialogue}
        handleCancel={() => {
          setShowCreateDeckDialogue(false);
        }}
        handleCreate={handleCreate}
      />
    </Container>
  );
}

export default DecksList;
